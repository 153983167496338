export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const LOG_ERROR = 'logError';
export const CHECK_AUTH = 'checkAuth';
export const UPDATE_USER = 'updateUser';
export const SET_AUTH_ERROR = 'setAuthError';
export const CHANGE_PASSWORD = 'changePassword';
export const RECOVER_PASSWORD = 'recoverPassword';
export const GET_LINKEDIN_ACCESS_TOKEN = 'getLinkedinAccessToken';

export const FETCH_BENEFITS = 'fetchBenefits';
export const FETCH_CONTENT = 'fetchContent';

export const DOWNLOAD_PDF = 'downloadPdf';
export const FETCH_SECTORS = 'fetchSectors';
export const FETCH_COUNTRIES = 'fetchCountries';
export const FETCH_SPECIALISMS = 'fetchSpecialisms';

export const FETCH_FAQS = 'fetchFaqs';
export const FETCH_FAQ_TAGS = 'fetchFaqsTags';
export const LEFT_FAQ_FEEDBACK = 'leftFaqFeedback';
export const LEFT_IS_FAQ_HELPFUL = 'leftIsFaqHelpful';
export const FETCH_FAQ_CATEGORIES = 'fetchFaqsCategories';

export const FETCH_PAI_DATA = 'fetchPaiData';
export const DOWNLOAD_PAI_DATA = 'downloadPaiData';
export const FETCH_PAI_INDICATORS = 'fetchPaiIndicators';
export const FETCH_PAI_DATA_SOURCES = 'fetchPaiDataSources';

export const FETCH_PAD_SDGS = 'fetchPadSDGs';
export const FETCH_PAD_DATA = 'fetchPadData';
export const DOWNLOAD_PAD_DATA = 'downloadPadData';
export const FETCH_PAD_INDICATORS = 'fetchPadIndicators';
export const FETCH_PAD_DATA_SOURCES = 'fetchPadDataSources';

export const FETCH_EWG_COUNTRIES = 'fetchEwgCountries';
export const FETCH_EWG_COUNTRY = 'fetchEwgCountry';

export const FETCH_EW_PROCESSES = 'fetchEwProcesses';
export const FETCH_EW_SUBPROCESS = 'fetchEwSubprocess';
export const FETCH_EW_SUBPROCESSES = 'fetchEwSubprocesses';

export const FETCH_PROFILE = 'fetchProfile';
export const UPDATE_PROFILE = 'updateProfile';
export const UPDATE_PASSWORD = 'updatePassword';
export const FETCH_PREFERENCES = 'fetchPreferences';
export const UPDATE_PREFERENCES = 'updatePreferences';

export const SEND_MESSAGE = 'sendMessage';

export const FETCH_ORGANISATION = 'fetchOrganisation';
export const FETCH_ORGANISATIONS = 'fetchOrganisations';
export const UPDATE_ORGANISATION = 'updateOrganisation';
export const CREATE_ORGANISATION = 'createOrganisation';

export const FETCH_MY_ORGANISATION = 'fetchMyOrganisation';
export const FETCH_MY_ORGANISATIONS = 'fetchMyOrganisations';
export const FETCH_TOP_ORGANISATIONS = 'fetchTopOrganisations';

export const FETCH_MEMBERS = 'fetchMembers';
export const REMOVE_MEMBER = 'removeMember';
export const INVITE_MEMBER = 'inviteMembers';

export const SAVE_VISUAL = 'saveVisual';
export const DELETE_VISUAL = 'deleteVisual';
export const FETCH_VISUALS = 'fetchVisuals';

export const FETCH_SYSTEMS_AND_POLICES = 'fetchSystemsAndPolices';
export const FETCH_TOOLTIP = 'fetchTooltip';

export const SOCIAL_LOGIN = 'socialLogin';
