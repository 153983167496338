<template>
	<div
		class="button button--action button--popup hide show@tablet"
		:class="mergedClasses"
	>
		<div @click.stop="tooltip = !tooltip">
			<img src="@/assets/imgs/icons/icon-share-white.svg" alt="" />
			Social
		</div>
		<div
			class="cursor-pointer"
			@click.stop="$emit('onDownloadClick')"
			v-if="download"
		>
			<img src="@/assets/imgs/icons/icon-download-white.svg" alt="" />
			Download
		</div>
		<div @click.stop="$emit('onSaveViewClick')" v-if="isAuthenticated">
			<img src="@/assets/imgs/icons/icon-save-white.svg" alt="" />
			Save view
		</div>
		<ShareTooltip @onClickOutside="closeTooltip" v-if="tooltip" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Actions',
	props: {
		download: {
			type: Boolean,
			default: false
		},
		classes: String
	},
	data() {
		return {
			tooltip: false
		};
	},
	computed: {
		...mapGetters(['isAuthenticated']),
		mergedClasses() {
			return `${this.classes}${this.isAuthenticated ? ' max-w-220' : ''}`;
		}
	},
	methods: {
		closeTooltip() {
			this.tooltip = false;
		}
	}
};
</script>
