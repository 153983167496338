<template>
	<div 
        class="org-section__partners__item" 
        :class="{'active': active}"
		@click="$emit('click', organisation)"
    >
		<div
			:key="`organisation-image-${organisation.slug}`"
			class="org-section__partners__item__image"
			v-if="organisation.image"
		>
			<img
				:src="organisation.image"
				:alt="organisation.name"
			/>
		</div>
		<VHeading 
            level="6" 
            weight="bold" 
            align="center" 
            key="organisation-name"
            v-else
        >
			{{ organisation.name.length > 40 ? organisation.name.slice(0, 40) + '...' : organisation.name }}
		</VHeading>
        <div 
            class="label label--purple" 
            :class="`label--${labelColor}`"
            v-if="organisation.type"
        >
            <VText 
                size="smaller" 
                uppercase
            >
                {{organisation.type}}
            </VText>
        </div>
	</div>
</template>

<script>
export default {
	props: {
        organisation: Object,
        active: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        labelColor() {
            switch (this.organisation.type) {
                case 'institution':
                    return 'purple';
                case 'policy':
                    return 'gray';
                case 'programme':
                    return 'blue';
            }
        }
    }
};
</script>
