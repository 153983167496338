<template>
	<label class="form__label">
		<slot />
		<sup v-if="required">*</sup>
	</label>
</template>

<script>
export default {
	props: {
		required: {
			type: Boolean,
			default: false
		}
	}
};
</script>
