import ApiService from '@/common/api.service';

export const OrganisationService = {
    query(params) {
        return ApiService.query('/organisation', params)
    },
    post(form) {
        return ApiService.post(`/organisation`, form)
    },
    get(slug) {
        return ApiService.get(`/organisation/${slug}`)
    },
    put({slug, form}) {
        return ApiService.put(`/organisation/${slug}`, form)
    }
};
