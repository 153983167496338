<template>
	<VMultiselect
		trackBy="id"
		:value="value"
		:multiple="multiple"
		:options="options"
		:classes="classes"
		:closeOnSelect="closeOnSelect"
		@input="$emit('input', $event)"
	/>
</template>

<script>
import { FETCH_COUNTRIES } from '@/store/actions.type';
import { mapState } from 'vuex';

export default {
	props: {
		value: {},
		queryParams: {
            type: Object,
			default: () => {}
		},
		multiple: {
			type: Boolean,
			default: true
		},
		classes: {
			type: String,
			default: ''
		},
		closeOnSelect: {
			type: Boolean,
			default: false
		},
		allAfrica: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			countries: state => state.app.countries
		}),
		options() {
			return this.allAfrica
				? [
						{ id: 'all-africa', name: 'All Africa', slug: 'all-africa' },
						...this.countries
				  ]
				: this.countries;
		}
	},
	watch: {
		queryParams: {
			handler: 'fetchCountries',
			immediate: true,
			deep: true
		}
	},
	methods: {
		fetchCountries() {
			this.$store.dispatch(FETCH_COUNTRIES, this.queryParams);
		}
	}
};
</script>
