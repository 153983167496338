import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {API_URL} from "@/common/config";
import JwtService from "@/common/jwt.service";

const ApiService = {
    init() {
        Vue.use(VueAxios, axios);
        Vue.axios.defaults.baseURL = API_URL;
        Vue.axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';

        if (JwtService.getToken()){
            Vue.axios.defaults.headers.common["Authorization"] = `${JwtService.getToken()}`;
        }
    },
    query(resource, params = {}) {
        try {
            return Vue.axios.get(`${resource}`, { params });
        } catch (error) {
            throw new Error(`[RWV] ApiService ${error}`);
        }
    },
    get(resource, slug = "", params = {}) {
        try {
            return Vue.axios.get(`${resource}/${slug}`, params)
        } catch (e) {
            throw new Error(`[RWV] ApiService ${e}`);
        }
    },
    post(resource, params) {
        return axios.post(`${resource}`, params);
    },
    update(resource, slug, params) {
        return Vue.axios.put(`${resource}/${slug}`, params);
    },
    put(resource, params) {
        return Vue.axios.put(`${resource}`, params);
    },
    delete(resource) {
        try {
            return Vue.axios.delete(resource);
        } catch (error) {
            throw new Error(`[RWV] ApiService ${error}`);
        }
    }
};

export default ApiService;
