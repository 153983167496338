<template>
	<div class="form__group__inner">
		<input
			:id="id"
			type="checkbox"
			class="form__checkbox"
			:checked="value"
			:disabled="disabled"
			@input="$emit('input', $event.target.checked)"
		/>
		<VLabel :for="id" class="label" :class="{ 'form__label--invalid': error }">
			<slot />
		</VLabel>
	</div>
</template>

<script>
export default {
	props: {
		disabled: { type: Boolean, default: false },
		id: { type: String, required: true },
		error: { type: String },
		label: { type: String },
		value: {}
	}
};
</script>
