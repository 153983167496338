import {
	SET_SECTORS,
	SET_COUNTRIES,
	SET_SPECIALISMS,
	SET_ACTIVE_MODAL,
	VIEW_WELCOME_MODAL,
	INITIALISE_VIEW_WELCOME_MODAL
} from '@/store/mutations.type';

import {
    LOG_ERROR,
    DOWNLOAD_PDF,
    FETCH_SECTORS,
    FETCH_COUNTRIES,
    FETCH_SPECIALISMS,
} from '@/store/actions.type';

import { PdfService } from '@/common/pdf.service';
import { ErrorService } from '@/common/error.service';
import { SectorService } from '@/common/sector.service';
import { CountryService } from '@/common/country.service';
import { SpecialismService } from '@/common/specialism.service';

import sectors from '@/data/sectors';
import countries from '@/data/countries';
import download from '../../data/pai/download';
import specialisms from '@/data/community/specialisms';

const state = {
	sectors: [],
	countries: [],
	specialisms: [],
	activeModal: null,
	viewWelcomeModal: false
};

const getters = {
	activeModalFile(state) {
		const modal = state.activeModal;
		return () => import(`@/modals/${modal}.vue`);
	}

};

const actions = {
	async [FETCH_COUNTRIES]({ commit }, params = {}) {
		const { data } = await CountryService.get(params);
		commit(SET_COUNTRIES, data.data);
	},
	async [FETCH_SPECIALISMS]({ commit }, params = {}) {
		const { data } = await SpecialismService.get(params);
		commit(SET_SPECIALISMS, data.data);
	},
	async [FETCH_SECTORS]({ commit }, params = {}) {
		const { data } = await SectorService.get(params);
		commit(SET_SECTORS, data.data);
	},
	async [DOWNLOAD_PDF]({ commit }, params) {
		return await PdfService.post(params);
	},
	async [LOG_ERROR]({ commit }, params) {
		return await ErrorService.post(params);
	}
};

const mutations = {
	[SET_COUNTRIES](state, data) {
		state.countries = data;
	},
	[SET_SPECIALISMS](state, data) {
		state.specialisms = data;
	},
	[SET_SECTORS](state, data) {
		state.sectors = data;
	},
	[SET_ACTIVE_MODAL](state, activeModal) {
		state.activeModal = activeModal;
	},
	[VIEW_WELCOME_MODAL](state) {
		localStorage.setItem('viewWelcomeModal', true);
		state.viewWelcomeModal = true;
	},
	[INITIALISE_VIEW_WELCOME_MODAL](state) {
		if (localStorage.getItem('viewWelcomeModal')) {
			state.viewWelcomeModal = true;
		}
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
