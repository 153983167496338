import { FETCH_SYSTEMS_AND_POLICES } from '@/store/actions.type';

import {
	SET_SYSTEMS_AND_POLICES,
	SET_ACTIVE_ORGANISATION
} from '@/store/mutations.type';

import organisations from '@/data/ewg/systems-and-policies/organisations';

import { SystemAndPolicyService } from '@/common/ewg/system-and-policy.service';

const state = {
	organisations: [],
	activeOrganisation: null,
	filters: {
		type: 'everything',
		sectors: []
	}
};

const getters = {
	snpFilters: state => state.filters
};

const actions = {
	async [FETCH_SYSTEMS_AND_POLICES]({ commit }, {country, params}) {
		const { data } = await SystemAndPolicyService.query(country, params);
		commit(SET_SYSTEMS_AND_POLICES, data.data);
	}
};

const mutations = {
	[SET_SYSTEMS_AND_POLICES](state, data) {
		state.organisations = data;
	},
	[SET_ACTIVE_ORGANISATION](state, data) {
		state.activeOrganisation = data;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};
