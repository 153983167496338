import { FETCH_TOOLTIP } from '@/store/actions.type';
import { OPEN_ALERT, CLOSE_ALERT } from '@/store/mutations.type';
import { TooltipService } from '@/common/tooltip.service';

import tooltip from '@/data/tooltip';

const state = {
	options: null
};

const actions = {
	async [FETCH_TOOLTIP](context, slug) {
		return await TooltipService.get(slug);
	},
};

const mutations = {
	[OPEN_ALERT](state, { type, title, content, width = 'medium' }) {
		state.options = { type, title, content, width };
	},
	[CLOSE_ALERT](state) {
		state.options = null;
	}
};

export default {
	state,
	actions,
	mutations
};
