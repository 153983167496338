<template>
	<div class="form__multiselect beige" :class="classes">
		<multiselect
			:label="label"
			:value="value"
			:track-by="trackBy"
			:options="options"
			:multiple="multiple"
			:taggable="true"
			:allow-empty="allowEmpty"
			:placeholder="placeholder"
			:close-on-select="closeOnSelect"
			v-bind="!allowEmpty ? { 'deselect-label': '' } : {}"
			@input="$emit('input', $event)"
			@select="$emit('select', $event)"
		>
			<span
				class="multiselect__arrow"
				slot="caret"
				slot-scope="{ toggle }"
				@mousedown.prevent.stop="toggle"
			>
				<img src="@/assets/imgs/icons/icon-arrow-down.svg" alt="" />
			</span>
		</multiselect>
	</div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
	name: 'VMultiselect',
	components: {
		Multiselect
	},
	props: {
		closeOnSelect: {
			type: Boolean,
			default: false
		},
		classes: {
			type: String,
			default: ''
		},
		multiple: {
			type: Boolean,
			default: true
		},
		options: {
			type: Array,
			default: () => []
		},
		placeholder: {
			type: String,
			default: 'Select option'
		},
		trackBy: {
			type: String,
			default: 'id'
		},
		label: {
			type: String,
			default: 'name'
		},
		allowEmpty: {
			type: Boolean,
			default: true
		},
		value: {}
	}
};
</script>
