import {
	FETCH_PAI_DATA,
	DOWNLOAD_PAI_DATA,
	FETCH_PAI_INDICATORS,
	FETCH_PAI_DATA_SOURCES
} from '../actions.type';

import {
	SET_PAI_DATA,
	SET_PAI_INDICATORS,
	SET_PAI_DATA_SOURCES
} from '../mutations.type';

import { DataSourceService } from '@/common/pai/data-source.service';
import { IndicatorService } from '@/common/pai/indicator.service';
import { PaiService } from '@/common/pai/pai.service';

import bubbleChartData from '../../data/pai/bubble-chart-data';
import barChartData from '../../data/pai/bar-chart-data';
import dataSources from '../../data/pai/data-sources';
import indicators from '../../data/pai/indicators';
import download from '../../data/pai/download';

const state = {
	data: null,
	indicators: [],
	dataSources: [],
	filters: {
		countries: [],
		indicator: null
	}
};

const getters = {
	paiFilters: state => state.filters
};

const actions = {
	async [FETCH_PAI_DATA]({ commit, state }, params) {
		const { data } = await PaiService.get(params);
		commit(SET_PAI_DATA, data);
	},
	[DOWNLOAD_PAI_DATA](context, form) {
        return PaiService.download(form);
	},
	async [FETCH_PAI_INDICATORS]({ commit }) {
		const { data } = await IndicatorService.get();
		commit(SET_PAI_INDICATORS, data);
	},
	async [FETCH_PAI_DATA_SOURCES]({ commit }, params) {
		const { data } = await DataSourceService.query(params);
		commit(SET_PAI_DATA_SOURCES, data.data);
	}
};

const mutations = {
	[SET_PAI_DATA](state, data) {
		state.data = data;
	},
	[SET_PAI_DATA_SOURCES](state, data) {
		state.dataSources = data;
	},
	[SET_PAI_INDICATORS](state, data) {
		state.indicators = data;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
