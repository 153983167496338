import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';

import '@/components.js';
import '@/helpers/loader';
import '@/styles/main.scss';
// import '@/registerServiceWorker';

import ApiService from '@/common/api.service';
import DateFilter from '@/filters/date.filter';
import clickOutside from '@/directives/click-outside';
import { CHECK_AUTH, FETCH_MY_ORGANISATIONS } from '@/store/actions.type';

import VueSwal from 'vue-swal';
import Croppa from 'vue-croppa';
import VueToastr from 'vue-toastr';
import PortalVue from 'portal-vue';
import 'coffeekraken-gridle/index';
import Fragment from 'vue-fragment';
import 'vue-croppa/dist/vue-croppa.css';

Vue.use(VueSwal);
Vue.use(Croppa);
Vue.use(PortalVue);
Vue.use(Fragment.Plugin);
Vue.filter('date', DateFilter);
Vue.directive('click-outside', clickOutside);
Vue.use(VueToastr, {
	defaultPosition: 'toast-bottom-right',
	defaultStyle: {
		// backgroundColor: '#29a87c',
		fontFamily: 'Muli'
	}
});

router.beforeEach(async (to, from, next) => {
	if (store.state.app.isAuthenticated && to.name !== 'organisation') {
		await store.dispatch(FETCH_MY_ORGANISATIONS);

		if (!store.state.organisation.organisations.length) {
			next('/account/organisation');
		}
	}

	Promise.all([store.dispatch(CHECK_AUTH)]).then(next);
});

Vue.config.productionTip = false;

ApiService.init();

import '@/common/http.service';

import GAuth from 'vue-google-oauth2';
import { organisation } from '@/store/modules/account/organisation.module';

const gauthOption = {
	clientId:
		'378194348348-ek49kkdj8njgiokq8tkpqf10ecjsubrl.apps.googleusercontent.com',
	scope: 'profile email',
	prompt: 'select_account'
};

Vue.use(GAuth, gauthOption);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
