import store from '@/store';
import axios from 'axios';
import Vue from 'vue';

import {
	START_LOADING,
	FINISH_LOADING,
	RESET_LOADING
} from '@/store/mutations.type';

axios.interceptors.request.use(config => {
	if (config.url !== '/profile') {
		store.commit(START_LOADING);
	}

	return config;
});

axios.interceptors.response.use(
	response => {
		store.commit(FINISH_LOADING);
		return response;
	},
	error => {
		const vm = new Vue();

		if (error.response && error.response.status == 500) {
			vm.$toastr.e('Error occurred. Please try later.');
		}

		if (error.response && error.response.status === 401) {
			store.commit('setActiveModal', 'LoginModal');
		}

		store.commit(RESET_LOADING);
		return Promise.reject(error);
	}
);
