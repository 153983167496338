<template>
	<div class="tab-section__content">
		<div class="tab-section__content__header">
			<VHeading level="2" screen="mobile" :style="styling">
				<slot name="title"></slot>
			</VHeading>
			<div class="limiter" v-if="limiter">
				<img
					src="@/assets/imgs/icons/icon-line.svg"
					alt="line icon"
					class="hide show@tablet"
				/>
				<img
					src="@/assets/imgs/icons/icon-line-horizontal.svg"
					class="hide show@max-tablet"
					alt="horizontal line icon"
				/>
			</div>
			<VHeading level="4" weight="light" screen="mobile" v-if="$slots.subtitle">
				<slot name="subtitle"></slot>
			</VHeading>
		</div>

		<slot name="content"></slot>
	</div>
</template>

<script>
export default {
	name: 'Tab',
	props: {
		limiter: {
			type: Boolean,
			default: true
		},
		styling: {
			type: String,
			default: ''
		}
	}
};
</script>
