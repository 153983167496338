import Vue from "vue";
import Vuex from "vuex";

import ewg from "@/store/modules/ewg.module";
import pad from "@/store/modules/pad.module";
import pai from "@/store/modules/pai.module";
import faq from "@/store/modules/faq.module";
import app from "@/store/modules/app.module";
import auth from "@/store/modules/auth.module";
import alert from "@/store/modules/alert.module";
import loader from "@/store/modules/loader.module";
import visual from "@/store/modules/visual.module";
import content from "@/store/modules/content.module";
import socials from "@/store/modules/socials.module";
import community from "@/store/modules/community.module";
import contactUs from "@/store/modules/contact-us.module";
import profile from "@/store/modules/account/profile.module";
import organisation from "@/store/modules/account/organisation.module";
import topOrganisations from "@/store/modules/top-organisations.module";
import systemAndPolices from "@/store/modules/system-and-policy.module";
import engagementWindows from "@/store/modules/engagement-windows.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        engagementWindows,
        topOrganisations,
        systemAndPolices,
        organisation,
        contactUs,
        community,
        socials,
        content,
        profile,
        loader,
        visual,
        alert,
        auth,
        app,
        faq,
        pai,
        ewg,
        pad
    }
});
