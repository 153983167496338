<template>
	<div class="footer">
		<div class="footer__inner gr gr--wrap gr--flex">
			<div class="col col--4@tablet col--12">
				<div class="footer__left-top">
					<div class="footer__inner__image">
						<img src="@/assets/imgs/logo/logo.png" alt="" />
					</div>

					<VText classes="footer__inner__about">
						<div v-html="content"></div>
					</VText>

					<VHeading
						level="6"
						classes="footer__inner__title footer__inner__title--social"
					>
						Stay in touch
					</VHeading>
				</div>
				<div class="footer__left-bottom">
					<div class="footer__inner__social">
						<div class="footer__inner__social__icon">
							<VLink
								route="/contact"
								classes="footer__inner__link footer__inner__link--quick"
							>
								<img
									src="@/assets/imgs/social/email.svg"
									alt=""
								/>
							</VLink>
						</div>

						<div class="footer__inner__social__icon">
							<img
								src="@/assets/imgs/social/twitter.svg"
								alt=""
							/>
						</div>

						<div class="footer__inner__social__icon">
							<img
								src="@/assets/imgs/social/facebook.svg"
								alt=""
							/>
						</div>

						<div class="footer__inner__social__icon">
							<img src="@/assets/imgs/social/n.svg" alt="" />
						</div>
					</div>

					<VText>2021 Takwimu <u> CC by 4.0</u></VText>
				</div>
			</div>
			<div class="col col--3@desktop col--4@tablet col--12">
				<div class="footer__center">
					<VHeading level="6" classes="footer__inner__title">
						Quick links
					</VHeading>

					<VLink
						route="/policy-advocacy-index"
						classes="footer__inner__link footer__inner__link--quick"
						@click="scrollToTop"
					>
						Dashboard
					</VLink>
					<VLink
						route="/community"
						classes="footer__inner__link footer__inner__link--quick"
						@click="scrollToTop"
					>
						Community
					</VLink>
					<VLink
						route="/about"
						classes="footer__inner__link footer__inner__link--quick"
					>
						About Takwimu
					</VLink>
					<VLink
						route="/knowledgebase"
						classes="footer__inner__link footer__inner__link--quick"
						@click="scrollToTop"
					>
            Knowledgebase
					</VLink>
					<VLink
						route="/contact"
						classes="footer__inner__link footer__inner__link--quick"
					>
						Contact Us
					</VLink>
					<br /><br />
					<VLink
              route="/terms"
              classes="footer__inner__link footer__inner__link--quick"
					>
						Terms of use
					</VLink>
					<VLink
						route="/privacy"
						classes="footer__inner__link footer__inner__link--quick"
					>
						Privacy Policy
					</VLink>
				</div>
			</div>
			<div class="col col--1@tablet hide show@desktop"></div>
			<div class="col col--3@tablet col--12">
				<div class="footer__org">
					<div class="footer__right-top">
						<VHeading
							level="6"
							classes="footer__inner__title footer__inner__title--initiative"
              style="margin-bottom: 30px"
						>
							An Africa Practice initiative
						</VHeading>
            <VHeading
                level="6"
                classes="footer__inner__title footer__inner__title--initiative"
                style="margin-bottom: 15px; font-size: 16px;"
            >
              Partners
            </VHeading>
            <div class="footer__inner__partner">
              <img src="@/assets/imgs/logo/cfa.png" alt="" style="max-height: 40px; margin-right: 15px"/>
              <img src="@/assets/imgs/logo/ihub.png" alt="" style="max-height: 40px;"/>
            </div>
					</div>
					 <div class="footer__right-bottom">
						<VHeading
							level="6"
							classes="footer__inner__title footer__inner__title--initiative"
              style="margin-bottom: 15px; font-size: 16px"
            >
							With support from
						</VHeading>
						<div class="footer__inner__partner">
							<img src="@/assets/imgs/logo/gates.png" alt="" />
						</div>
					</div>
				</div>
				<div class="footer__right__mobile">
					<VHeading
						level="6"
						class="footer__inner__title footer__inner__title--social mobile"
					>
						Stay in touch
					</VHeading>
					<div class="footer__inner__social">
						<div class="footer__inner__social__icon">
							<VLink
								route="/contact"
								classes="footer__inner__link footer__inner__link--quick"
							>
								<img
									src="@/assets/imgs/social/email.svg"
									alt=""
								/>
							</VLink>
						</div>

						<div class="footer__inner__social__icon">
							<img
								src="@/assets/imgs/social/twitter.svg"
								alt=""
							/>
						</div>

						<div class="footer__inner__social__icon">
							<img
								src="@/assets/imgs/social/facebook.svg"
								alt=""
							/>
						</div>

						<div class="footer__inner__social__icon">
							<img src="@/assets/imgs/social/n.svg" alt="" />
						</div>
					</div>

					<VText>2021 Takwimu <u> CC by 4.0</u></VText>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import content from '@/mixins/content';
import scrollToTop from '@/mixins/scroll-to-top';

export default {
	name: 'Footer',
	mixins: [content, scrollToTop],
	created() {
		this.fetchContent('footer-description');
	}
};
</script>
