<template>
	<div class="org-section__partners">
		<OrganisationCard
			v-for="(organisation, index) in organisations"
			:key="`${organisation.slug}-${index}`"
			:organisation="organisation"
			@click="handleClick"
		></OrganisationCard>
	</div>
</template>

<script>
import { FETCH_TOP_ORGANISATIONS } from '@/store/actions.type';

import OrganisationCard from '@/components/common/OrganisationCard';

export default {
	components: {
		OrganisationCard
	},
	props: {
		top: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		organisations() {
			return this.top
				? this.$store.state.topOrganisations.organisations
				: this.$store.state.community.organisations;
		}
	},
	created() {
		this.fetchOrganisations();
	},
	methods: {
		handleClick(organisation) {
			this.$router
				.push({
					name: 'organisation-profile',
					params: { slug: organisation.slug }
				})
				.catch(e => {});
		},
		async fetchOrganisations() {
			if (this.top) {
				this.$store.dispatch(FETCH_TOP_ORGANISATIONS);
			}
		}
	}
};
</script>
