import ApiService from '@/common/api.service';

export const MemberService = {
	get(slug) {
		return ApiService.query(`/organisation/${slug}/member`);
	},
	post(slug, form) {
		return ApiService.post(`/organisation/${slug}/member`, form);
	},
	delete({ organisation, member, asAdmin }) {
		let endpoint = `/organisation/${organisation}/member/${member}`;

		if (asAdmin) endpoint = endpoint + '?asAdmin=true';

		return ApiService.delete(endpoint);
	}
};
