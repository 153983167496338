import { getURLWithQueryParams } from '@/helpers/generic';
import { APP_URL } from '@/common/config';

const LINKEDIN_SCOPE = 'r_liteprofile r_emailaddress';
const LINKEDIN_RIDERECT = `${APP_URL}/redirect`;
const LINKEDIN_CLIENT_ID = '77655ti7gma691';
export const LINKEDIN_STATE = 'random_string';

export const LINKEDIN_URL = getURLWithQueryParams(
	'https://www.linkedin.com/oauth/v2/authorization',
	{
		response_type: 'code',
		client_id: LINKEDIN_CLIENT_ID,
		redirect_uri: LINKEDIN_RIDERECT,
		state: LINKEDIN_STATE,
		scope: LINKEDIN_SCOPE
	}
);
