export const SET_AUTH = 'setAuth';
export const SET_USER = 'setUser';
export const PURGE_AUTH = 'logOut';
export const SET_ERROR = 'setError';
export const OPEN_ALERT = 'openAlert';
export const CLOSE_ALERT = 'closeAlert';
export const CLEAR_ERRORS = 'clearErrors';
export const START_LOADING = 'startLoading';
export const RESET_LOADING = 'resetLoading';
export const FINISH_LOADING = 'finishLoading';

export const SET_SECTORS = 'setSectors';
export const SET_COUNTRIES = 'setCountries';
export const SET_ACTIVE_MODAL = 'setActiveModal';
export const VIEW_WELCOME_MODAL = 'viewWelcomeModal';
export const INITIALISE_VIEW_WELCOME_MODAL = 'initialiseViewWelcomeModal';

export const SET_FAQS = 'setFaqs';
export const SET_FAQ_TAGS = 'setFaqTags';
export const SET_FAQ_CATEGORIES = 'setFaqCategories';

export const SET_PAI_DATA = 'setPaiData';
export const SET_PAI_INDICATORS = 'setPaiIndicators';
export const SET_PAI_DATA_SOURCES = 'setPaiDataSources';
export const SET_PRESELECTED_COUNTRIES = 'setPreselectedCountries';

export const SET_PAD_SDG = 'setPadSDG';
export const SET_PAD_KEYS = 'setPadKeys';
export const SET_PAD_DATA = 'setPadData';
export const SET_PAD_SDGS = 'setPadSDGs';
export const SET_PAD_TITLE = 'setPadTitle';
export const SET_PAD_INDICATORS = 'setPadIndicators';
export const SET_PAD_DATA_SOURCES = 'setPadDataSources';
export const SET_PAD_ADDITIONAL_DATA = 'setPadAdditionalData';

export const SET_SOCIAL_AUTH = 'setSocialAuth';

export const SET_EWG_COUNTRIES = 'setEwgCountries';
export const SET_EWG_COUNTRY = 'setEwgCountry';

export const SET_EW_FILTERS = 'setEwFilters';
export const SET_EW_PROCESSES = 'setEwProcesses';
export const SET_EW_SUBPROCESS = 'setEwSubprocess';
export const SET_EW_SUBPROCESSES = 'setEwSubprocesses';

export const SET_PROFILE = 'setProfile';
export const SET_PREFERENCES = 'setPreferences';

export const SET_SPECIALISMS = 'setSpecialisms';
export const SET_ORGANISATIONS = 'setOrganisations';

export const SET_MY_ORGANISATIONS = 'setMyOrganisations';
export const SET_CURRENT_ORGANISATION = 'setCurrentOrganisation';

export const SET_MEMBERS = 'setMembers';

export const SET_VISUALS = 'setVisuals';

export const SET_SYSTEMS_AND_POLICES = 'setSystemAndPolices';

export const SET_ACTIVE_ORGANISATION = 'setActiveOrganisation';

export const SET_TOP_ORGANISATIONS = 'setTopOrganisations';
