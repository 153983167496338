import { FETCH_BENEFITS, FETCH_CONTENT } from '@/store/actions.type';

import { BenefitService } from '@/common/benefit.service';
import { ContentService } from '@/common/content.service';

const actions = {
	[FETCH_BENEFITS]() {
		return BenefitService.get();
	},
	[FETCH_CONTENT](context, slug) {
		return ContentService.get(slug);
	}
};

export default {
	actions
};
