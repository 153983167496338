import {
    FETCH_ORGANISATIONS,
    FETCH_ORGANISATION,
} from '../actions.type';

import {
    SET_ORGANISATIONS,
} from '../mutations.type';

import organisations from '../../data/organisation/organisations';
import organisation from '../../data/organisation/organisation';

import {OrganisationService} from '../../common/organisation/organisation.service';

const state = {
    organisations: []
};

const actions = {
    async [FETCH_ORGANISATIONS](context, params) {
        const { data } = await OrganisationService.query(params);
        context.commit(SET_ORGANISATIONS, data.data);
    },
    async [FETCH_ORGANISATION](context, slug) {
        const { data } = await OrganisationService.get(slug);
        return data.data;
    }
};

const mutations = {
    [SET_ORGANISATIONS](state, data) {
        state.organisations = data;
    }
};

export default {
    state,
    actions,
    mutations
};
