import ApiService from '@/common/api.service';

export const PadService = {
    get(indicator, params) {
        return ApiService.query(`/pad/${indicator}`, params);
    },
    download(indicator, form) {
        return ApiService.post(`/pad/${indicator}/download`, form);
    }
};
