<template>
	<div class="modal-section__card__content__inner social">
		<VText size="big" weight="bold">
			{{ type }} with an existing social account:
		</VText>

		<VButton
			social="google"
			:disabled="type.includes('Register') && !agree"
			@click="googleAuth"
		>
			<img
				src="@/assets/imgs/icons/icon-google.svg"
				class="button--social--icon"
				alt="google"
			/>
			{{ type }} with Google
		</VButton>

		<VButton
			social="linkedin"
			:disabled="type.includes('Register') && !agree"
			@click="linkedinAuth"
		>
			<img
				src="@/assets/imgs/icons/icon-linkedin.svg"
				class="button--social--icon"
				alt="linkedin"
			/>
			{{ type }} with Linkedin
		</VButton>

		<VDefaultField v-if="type.includes('Register')">
			<template #field>
				<VCheckbox id="terms-social" v-model="agree">
					I agree to your
					<VLink size="small" href="/privacy" target="_blank">
						terms and privacy policy
					</VLink>
				</VCheckbox>
			</template>
		</VDefaultField>

		<!--				<VButton social="twitter">-->
		<!--					<img-->
		<!--						src="@/assets/imgs/icons/icon-twitter.svg"-->
		<!--						class="button&#45;&#45;social&#45;&#45;icon"-->
		<!--						alt=""-->
		<!--					/>-->
		<!--					Login with Twitter-->
		<!--				</VButton>-->

		<!--				<VButton social="facebook">-->
		<!--					<img-->
		<!--						src="@/assets/imgs/icons/icon-facebook.svg"-->
		<!--						class="button&#45;&#45;social&#45;&#45;icon"-->
		<!--						alt=""-->
		<!--					/>-->
		<!--					Login with Facebook-->
		<!--				</VButton>-->
	</div>
</template>

<script>
import { SET_ACTIVE_MODAL } from '@/store/mutations.type';
import { LINKEDIN_STATE, LINKEDIN_URL } from '@/helpers/linkedin';
import { SOCIAL_LOGIN, GET_LINKEDIN_ACCESS_TOKEN, LOG_ERROR } from '@/store/actions.type';

export default {
	props: {
		type: {
			type: String,
			validator: val => ['Register', 'Login'].includes(val)
		}
	},
	data() {
		return {
			agree: false,
			popup: null
		};
	},
	beforeDestroy() {
		window.removeEventListener('message', this.receiveLinkedInMessage);
		this.popup && this.popup.close();
	},
	methods: {
		async providerLogin(form) {
			await this.$store.dispatch(SOCIAL_LOGIN, form);

			this.$store.commit(SET_ACTIVE_MODAL, null);

			window.location.reload(); // todo needs solution without reload
		},
		async googleAuth() {
			try {
				const googleUser = await this.$gAuth.signIn();
				const authResponse = googleUser.getAuthResponse();
				const accessToken = authResponse.access_token;

				this.providerLogin({
					provider: 'google',
					accessToken: accessToken
				});

			} catch (error) {
				this.$store.dispatch(LOG_ERROR, `Google Login Error --- ${error}`);
			    this.$toastr.e('Error', 'Some error occurred.');
			}
		},
		linkedinAuth() {
			this.popup = window.open(LINKEDIN_URL, '_blank', 'width=600,height=600');

			window.addEventListener('message', this.receiveLinkedInMessage);
		},
		async receiveLinkedInMessage({ origin, data: { state, code, error } }) {
			if (origin !== window.location.origin || state !== LINKEDIN_STATE) return;

			try {
				if (code) {
					const {
						data: { access_token }
					} = await this.$store.dispatch(GET_LINKEDIN_ACCESS_TOKEN, { code });

					this.providerLogin({
						provider: 'linkedin',
						accessToken: access_token
					});
				} else if (error && !['user_cancelled_login', 'user_cancelled_authorize'].includes(error)) {
                    this.$store.dispatch(LOG_ERROR, `Google Login Error --- ${error}`);
					this.$toastr.e('Error', 'Some error occurred.');
				}
			} catch (error) {
				this.$store.dispatch(LOG_ERROR, `Linkedin Login Error --- ${error}`);
				this.$toastr.e('Error', 'Some error occurred.');
			} finally {
			    this.popup.close();
            }
		}
	}
};
</script>
