import { FETCH_TOP_ORGANISATIONS } from '@/store/actions.type';

import { SET_TOP_ORGANISATIONS } from '@/store/mutations.type';

import { OrganisationService } from '@/common/organisation/organisation.service';

const state = {
	organisations: []
};

const actions = {
	async [FETCH_TOP_ORGANISATIONS](context) {
		const { data } = await OrganisationService.query({ top: true });
		context.commit(SET_TOP_ORGANISATIONS, data.data);
	}
};

const mutations = {
	[SET_TOP_ORGANISATIONS](state, data) {
		state.organisations = data;
	}
};

export default {
	state,
	actions,
	mutations
};
