<template>
	<div :class="mergedClasses">
		<label :class="labelClasses" v-if="label">
			{{ label }}
		</label>

		<div class="row-align w-100">
			<slot name="field" />

			<small class="invalid" v-if="error">
				<VText weight="bold" size="small">{{ error }}</VText>
			</small>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		error: {
			type: String,
			default: null
		},
		classes: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		labelClasses: {
			type: String,
			default: 'form__label-field--inline right'
		},
		inline: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		mergedClasses() {
			return this.inline
				? `form__group__inline ${this.classes}`
				: `form__group ${this.classes}`;
		}
	}
};
</script>
