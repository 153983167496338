import {
   // SOCIAL_LOGIN
} from '@/store/actions.type';

import {SocialService} from '@/common/social.service';

const actions = {
    /*async [SOCIAL_LOGIN](context, form) {
        const { data } = await SocialService.post(form);
    }*/
};

export default {
    actions,
};
