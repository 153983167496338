import { ProfileService } from '@/common/profile/profile.service';
import { SocialService } from '@/common/social.service';
import { AuthService } from '@/common/auth.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import Errors from '@/helpers/Errors';

import {
	LOGIN,
	LOGOUT,
	REGISTER,
	CHECK_AUTH,
	SOCIAL_LOGIN,
	SET_AUTH_ERROR,
	CHANGE_PASSWORD,
	RECOVER_PASSWORD,
	GET_LINKEDIN_ACCESS_TOKEN
} from '@/store/actions.type';

import {
	SET_USER,
	SET_AUTH,
	SET_ERROR,
	PURGE_AUTH,
	CLEAR_ERRORS,
	SET_SOCIAL_AUTH
} from '@/store/mutations.type';

const state = {
	user: {},
	errors: new Errors(),
	isAuthenticated: !!JwtService.getToken()
};

const getters = {
	currentUser: state => state.user,
	isAuthenticated: state => state.isAuthenticated
};

const actions = {
	async [SOCIAL_LOGIN]({ commit }, form) {
		const { data } = await SocialService.post(form);
		commit(SET_SOCIAL_AUTH, data);
	},
	[GET_LINKEDIN_ACCESS_TOKEN]({ commit }, form) {
		return SocialService.getLinkedin(form);
	},
	[LOGIN]({ commit }, form) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await AuthService.login(form);
				commit(SET_AUTH, data);
				commit(SET_USER, data.user);
				resolve(data);
			} catch (errors) {
				if (errors.response.status === 422) {
					commit(SET_ERROR, errors.response.data.errors);
				}

				if (errors.response.status === 401) {
					commit(SET_ERROR, { detail: [errors.response.data.message] });
				}

				reject(errors);
			}
		});
	},
	[LOGOUT]({ commit }) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await AuthService.logout();
				commit(PURGE_AUTH);
				resolve(data);
			} catch (errors) {
				commit(SET_ERROR, errors.response.data.errors);
				reject();
			}
		});
	},
	[REGISTER]({ commit }, form) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await AuthService.register(form);
				commit(SET_AUTH, data);
				commit(SET_USER, data.user);
				resolve(data);
			} catch (errors) {
				commit(SET_ERROR, errors.response.data.errors);
				reject();
			}
		});
	},
	[SET_AUTH_ERROR]({ commit }, errors) {
		commit(SET_ERROR, errors);
	},
	[RECOVER_PASSWORD]({ commit }, form) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await AuthService.recoverPassword(form);
				commit(SET_AUTH, data.data);
				resolve(data);
			} catch ({ response }) {
				commit(SET_ERROR, response.data);
				reject();
			}
		});
	},
	[CHANGE_PASSWORD]({ commit }, form) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await AuthService.changePassword(form);
				commit(SET_AUTH, data.data);
				resolve(data);
			} catch ({ response }) {
				commit(SET_ERROR, response.data);
				reject();
			}
		});
	},
	async [CHECK_AUTH]({ commit }) {
		if (JwtService.getToken()) {
			try {
				const { data } = await ProfileService.get();
				commit(SET_AUTH, data.data);
				commit(SET_USER, data.data);
			} catch ({ response }) {
				commit(PURGE_AUTH);
			}
		} else {
			commit(PURGE_AUTH);
		}
	}
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors.record(error);
	},
	[CLEAR_ERRORS](state) {
		for (const error in state.errors.errors) {
			state.errors.clear(error);
		}
	},
	[SET_AUTH](state, data) {
		state.isAuthenticated = true;

		state.errors.clearAll();

		if (data.Authorization) {
			JwtService.saveToken(data.Authorization);
		}

		ApiService.init();
	},
	[SET_USER](state, data) {
		state.user = {
			...data,
			...{
				initialPhoto: data.profilePhoto
					? data.profilePhoto
					: require('@/assets/imgs/profile/default_user.jpg')
			}
		};
	},
	[SET_SOCIAL_AUTH](state, data) {
		state.isAuthenticated = true;

		state.user = data;

		state.errors.clearAll();

		if (data.Authorization) {
			JwtService.saveToken(data.Authorization);
		}

		ApiService.init();
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = {};
		JwtService.destroyToken();
	}
};

export default {
	state,
	actions,
	mutations,
	getters
};
