import {
	START_LOADING,
	FINISH_LOADING,
	RESET_LOADING
} from '@/store/mutations.type';

const state = {
	loading: 0
};

const getters = {
	loading: state => state.loading > 0
};

const mutations = {
	[START_LOADING]: state => state.loading++,
	[RESET_LOADING]: state => (state.loading = 0),
	[FINISH_LOADING]: state =>
		setTimeout(
			() => (state.loading > 0 ? state.loading-- : state.loading),
			1000
		)
};

export default {
	state,
	getters,
	mutations
};
