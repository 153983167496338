<template>
	<textarea
		class="form__textarea"
		:class="{ 'form__input--invalid': error }"
		@input="$emit('input', $event.target.value)"
	>{{ value }}</textarea>
</template>

<script>
export default {
	props: {
		value: {},
		error: { type: String },
	}
};
</script>
