import ApiService from '@/common/api.service';

export const ProfileService = {
    get() {
        return ApiService.query('/profile')
    },
    post(data) {
        return ApiService.post('/profile', data)
    }
};
