<template>
    <div :class="mergedClasses" @click="$emit('click', $event)">
        <slot/>
    </div>
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            validator: val => ['small', 'smaller', 'extra-small', 'regular', 'big', 'bigger'].includes(val)
        },
		align: {
			type: String,
			validator: val => ['right', 'center'].includes(val)
		},
        weight: {
            type: String,
            validator: val => ['semibold', 'bold', 'extra-bold', 'regular'].includes(val)
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        italic: {
            type: Boolean,
            default: false
        },
        classes: String
    },
    computed: {
        mergedClasses() {
            let classes = 'p';
            if (this.size) classes = classes.concat(` p--${this.size}`);
			if (this.align) classes = classes.concat(` ${this.align}`);
            if (this.weight) classes = classes.concat(` p--${this.weight}`);
            if (this.uppercase) classes = classes.concat(' p--uppercase');
            if (this.italic) classes = classes.concat(' p--italic');
            if (this.classes) classes = classes.concat(` ${this.classes}`);

            return classes;
        }
    },
}
</script>