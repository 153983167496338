<template>
	<label :for="id" class="form__radio__label">
		<input
			:id="id"
			name="radio"
			type="radio"
			class="form__radio"
			:value="inputValue"
			:checked="value === inputValue"
			@input="$emit('input', inputValue)"
		/>
		<span>{{ label }}</span>
		<div class="form__radio__indicator gray"></div>
	</label>
</template>

<script>
export default {
	name: 'RadioButton',
	props: {
		id: { type: String, required: true },
		label: { type: String },
		inputValue: {},
		value: {}
	}
};
</script>
