import ApiService from '@/common/api.service';

export const FaqFeedbackService = {
    post(id, form) {
        return ApiService.post(`/faq/${id}/feedback`, form)
    },
    postIsHelpful(id, isHelpful) {
        return ApiService.post(`/faq/${id}/feedback/helpful`, {helpful: isHelpful})
    }
};
