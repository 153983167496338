import {APP_URL} from '@/common/config.js';

export default [
    {
        id: 1,
        type: 'institution',
        slug: 'safe-hands-kenya',
        name: 'Safe Hands Kenya',
        country: {
            country_id: 14,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Bahrain',
            region: 'Middle East and North Africa',
            is_active: true
        },
        image: '/img/sh-klogos.95bcf679.png',
        coordinates: [0.0236, 37.9062],
        role: 'admin',
        resourceType: 'National Government',
        content: '<p>' +
            '<strong>Purpose</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            '<strong>Governance</strong>' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        socials: {
            twitter: 'https://twitter.com/KOKO_Networks',
            facebook: 'https://www.facebook.com/KOKOFUEL',
            instagram: null,
            linkedin: null
        },
        organogram: {},
        resources: [],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    },
    {
        id: 3,
        type: 'policy',
        slug: 'koko-networks-africa',
        name: 'Koko Networks Africa',
        country: {
            country_id: 105,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Liberia',
            region: 'West and Central Africa',
            is_active: true
        },
        image: 'https://cdn.zeplin.io/5fb326ebb6a77176e1e3176b/assets/A6C02AA2-A842-40F9-B4E1-30A09E97EDCC.png',
        coordinates: [2.3185, 19.5687],
        role: 'client',
        resourceType: 'Health sector policy',
        content: '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        resources: [
            {
                name: 'View resource',
                type: 'internal', //types: internal, external, download
                link: `${APP_URL}/engaging-with-government/systems-and-policies?active=koko-networks-africa`
            },
            {
                name: 'View Africa Practise Resource',
                type: 'download',
                link: `${APP_URL}/img/sh-klogos.95bcf679.png`
            }
        ],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    },
    {
        id: 4,
        type: 'programme',
        slug: 'new-organisation',
        name: 'New Organisation',
        image: null,
        country: {
            country_id: 110,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Madagascar',
            region: 'Southern and Eastern Africa',
            is_active: true
        },
        coordinates: [18.7669, 46.8691],
        role: 'admin',
        resourceType: 'Financial Inclusion Programme',
        content: '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        resources: [],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    },
    {
        id: 5,
        type: 'institution',
        slug: 'safe-hands-kenya3',
        name: 'Safe Hands Kenya',
        country: {
            country_id: 14,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Bahrain',
            region: 'Middle East and North Africa',
            is_active: true
        },
        image: '/img/sh-klogos.95bcf679.png',
        coordinates: [0.0236, 37.9062],
        role: 'admin',
        resourceType: 'National Government',
        content: '<p>' +
            '<strong>Purpose</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            '<strong>Governance</strong>' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        socials: {
            twitter: 'https://twitter.com/KOKO_Networks',
            facebook: 'https://www.facebook.com/KOKOFUEL',
            instagram: null,
            linkedin: null
        },
        organogram: {},
        resources: [
            {
                name: 'View policy',
                type: 'internal', //types: internal, external, download
                link: `${APP_URL}/engaging-with-government/systems-and-policies?active=koko-networks-africa`
            },
            {
                name: 'View Africa Practise Policy',
                type: 'external',
                link: 'https://africapractice.com/privacy-policy/'
            },
            {
                name: 'View Africa Practise Policy',
                type: 'download',
                link: `${APP_URL}/img/sh-klogos.95bcf679.png`
            }
        ],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    },
    {
        id: 6,
        type: 'policy',
        slug: 'koko-networks-africa654',
        name: 'Koko Networks Africa',
        country: {
            country_id: 105,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Liberia',
            region: 'West and Central Africa',
            is_active: true
        },
        image: 'https://cdn.zeplin.io/5fb326ebb6a77176e1e3176b/assets/A6C02AA2-A842-40F9-B4E1-30A09E97EDCC.png',
        coordinates: [2.3185, 19.5687],
        role: 'client',
        resourceType: 'Health sector policy',
        content: '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        resources: [
            {
                name: 'View policy',
                type: 'internal', //types: internal, external, download
                link: `${APP_URL}/engaging-with-government/systems-and-policies?active=koko-networks-africa`
            },
            {
                name: 'View Africa Practise Policy',
                type: 'external',
                link: 'https://africapractice.com/privacy-policy/'
            },
            {
                name: 'View Africa Practise Policy',
                type: 'download',
                link: `${APP_URL}/img/sh-klogos.95bcf679.png`
            },
        ],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    },
    {
        id: 7,
        type: 'programme',
        slug: 'new-organisation65',
        name: 'New Organisation',
        image: null,
        country: {
            country_id: 110,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Madagascar',
            region: 'Southern and Eastern Africa',
            is_active: true
        },
        coordinates: [18.7669, 46.8691],
        role: 'admin',
        resourceType: 'Financial Inclusion Programme',
        content: '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        resources: [
            {
                name: 'View resource',
                type: 'internal', //types: internal, external, download
                link: `${APP_URL}/engaging-with-government/systems-and-policies?active=koko-networks-africa`
            },
            {
                name: 'View Africa Practise Resource',
                type: 'download',
                link: `${APP_URL}/img/sh-klogos.95bcf679.png`
            },
        ],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            },
            {
                name: 'Koko Organisation TEST',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    },
    {
        id: 8,
        type: 'institution',
        slug: 'safe-hands-kenya355',
        name: 'Safe Hands Kenya',
        country: {
            country_id: 14,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Bahrain',
            region: 'Middle East and North Africa',
            is_active: true
        },
        image: '/img/sh-klogos.95bcf679.png',
        coordinates: [0.0236, 37.9062],
        role: 'admin',
        resourceType: 'National Government',
        content: '<p>' +
            '<strong>Purpose</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            '<strong>Governance</strong>' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>',
        socials: {
            twitter: 'https://twitter.com/KOKO_Networks',
            facebook: 'https://www.facebook.com/KOKOFUEL',
            instagram: null,
            linkedin: null
        },
        organogram: {},
        resources: [],
        contacts: []
    },
    {
        id: 9,
        type: 'programme',
        slug: 'new-organisation656765',
        name: 'New Organisation',
        image: null,
        country: {
            country_id: 110,
            created: '2020-12-17T15:46:59.034373+02:00',
            name: 'Madagascar',
            region: 'Southern and Eastern Africa',
            is_active: true
        },
        coordinates: [18.7669, 46.8691],
        role: 'admin',
        resourceType: 'Financial Inclusion Programme',
        content: '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>'
            +
            '<p>' +
            '<strong>Overview</strong>\n' +
            '</p>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>' +
            '<p>' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a' +
            'suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus' +
            'egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
            'Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla' +
            'cursus egestas.' +
            '</p>' +
            '<br/><br/>'
        ,
        resources: [
            {
                name: 'View policy',
                type: 'internal', //types: internal, external, download
                link: `${APP_URL}/engaging-with-government/systems-and-policies?active=koko-networks-africa`
            },
            {
                name: 'View Africa Practise Policy',
                type: 'download',
                link: `${APP_URL}/img/sh-klogos.95bcf679.png`
            },
        ],
        contacts: [
            {
                name: 'Koko Organisation',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            },
            {
                name: 'Koko Organisation 2',
                link: `${APP_URL}/organisation-profile/koko-networks-africa`
            }
        ]
    }
]
