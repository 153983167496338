import {
    FETCH_VISUALS,
    DELETE_VISUAL,
    SAVE_VISUAL
} from '@/store/actions.type';

import {
    SET_VISUALS,
} from '@/store/mutations.type';

import visuals from '@/data/visuals';

import {VisualService} from '@/common/visual.service';

const state = {
    visuals: []
};

const actions = {
    async [FETCH_VISUALS](context) {
       const { data } = await VisualService.get();
        context.commit(SET_VISUALS, data.data);
    },
    async [SAVE_VISUAL](context, form) {
       return VisualService.post(form);
    },
    async [DELETE_VISUAL](context, visual) {
         return VisualService.delete(visual.id);
    },
};


const mutations = {
    [SET_VISUALS](state, data) {
        state.visuals = data;
    },
};

export default {
    state,
    actions,
    mutations
};
