<template>
	<div class="navbar__filters">
		<Modal
			classes="modal--pa-index modal--download"
			class="modal--download modal--filters"
			@close="$emit('close')"
		>
			<template #header>
				<VHeading level="2">Filters</VHeading>
			</template>
			<template #content>
				<div>
					<div class="h6 navbar__filter__title">
						Compare by country
						<img
							src="@/assets/imgs/icons/icon-info-white.svg"
							alt="info icon"
                            @click="$emit('onInfoClick', 'Compare by country', 'compare-country')"
						/>
					</div>
					<div class="multiselect-content">
						<slot name="countries" />
					</div>
					<div class="h6 navbar__filter__title">
						Compare by indicator
						<img
							src="@/assets/imgs/icons/icon-info-white.svg"
							alt="info icon"
                            @click="$emit('onInfoClick', 'Compare by indicator', 'compare-indicator')"
						/>
					</div>
					<div class="tab-section__content__sidebar__navigation">
						<slot name="indicators" />
					</div>
				</div>
			</template>
			<template #footer>
				<VButton color="outline-black" classes="btn" @click="$emit('close')">
					Cancel
				</VButton>
				<VButton color="black" classes="btn" @click="$emit('onSubmit')">
					Apply selection
				</VButton>
			</template>
		</Modal>
	</div>
</template>

<script>
export default {
    name: 'SmFilters'
}
</script>