import {
	FETCH_PAD_DATA,
	FETCH_PAD_SDGS,
	DOWNLOAD_PAD_DATA,
	FETCH_PAD_INDICATORS,
	FETCH_PAD_DATA_SOURCES
} from '@/store/actions.type';

import {
	SET_PAD_SDG,
	SET_PAD_DATA,
	SET_PAD_SDGS,
	SET_PAD_KEYS,
	SET_PAD_TITLE,
	SET_PAD_INDICATORS,
	SET_PAD_DATA_SOURCES,
	SET_PAD_ADDITIONAL_DATA
} from '@/store/mutations.type';

import { PadService } from '@/common/pad/pad.service';
import { IndicatorService } from '@/common/pad/indicator.service';
import { DataSourceService } from '@/common/pad/data-source.service';

import sdgs from '@/data/pad/sdgs';
import odaKeys from '@/data/pad/oda/keys';
import download from '@/data/pad/download';
import sdgData from '@/data/pad/sdgs/data';
import indicators from '@/data/pad/indicators';
import dataSources from '@/data/pad/data-sources';
import odaChartData from '@/data/pad/oda/chart-data';
import totallyBudgetKeys from '@/data/pad/total-budget/keys';
import sdgDataWithSector from '@/data/pad/sdgs/data-with-sector';
import totallyBudgetChartData from '@/data/pad/total-budget/chart-data';
import budgetBySectorChartData from '@/data/pad/budget-by-sector/chart-data';
import budgetBySingleSectorChartData from '@/data/pad/budget-by-sector/chart-data-single';

const state = {
	data: null,
	title: '',
	keys: [],
	sdgs: [],
	indicators: [],
	dataSources: [],
	selectedSdg: null,
	additionalData: null,
	padFilters: {
		budgetPerCapita: false,
		indicator: null,
		countries: [],
		sector: null,
		subSdg: null,
		sdg: null
	}
};

const getters = {
	padFilters: state => state.padFilters,
	activeSdg: state => {
		if (state.padFilters.subSdg) return state.padFilters.subSdg;
		if (state.padFilters.sdg) return state.padFilters.sdg;
		return null;
	}
};

const actions = {
	async [FETCH_PAD_DATA]({ commit, state }, { indicator, params = {}, additionalData = false }) {
		if (!params.countries.length) {
			commit(SET_PAD_DATA, []);
			return;
		}

		const { data } = await PadService.get(indicator, params);
		commit(additionalData ? SET_PAD_ADDITIONAL_DATA : SET_PAD_DATA, data);
	},
	async [DOWNLOAD_PAD_DATA](context, { indicator, form }) {
		return PadService.download(indicator, form);
	},
	async [FETCH_PAD_SDGS]({ commit }) {
		// const { data } = await SdgService.get();

		// const sdgs = data.map(sdg => {
		//     sdg.order = sdg.subgoals[0].order.substring(0, sdg.subgoals[0].order.indexOf('.'))*1;
		//     sdg.subgoals = sdg.subgoals.map(subGoal => {
		//         subGoal.order = subGoal.order.split('.').pop()*1;
		//         return subGoal;
		//     }).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

		//     return sdg;
		// }).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

		commit(SET_PAD_SDGS, sdgs);
	},
	async [FETCH_PAD_DATA_SOURCES]({ commit }, params) {
		const { data } = await DataSourceService.query(params);
		commit(SET_PAD_DATA_SOURCES, data);
	},
	async [FETCH_PAD_INDICATORS]({ commit }) {
		const { data } = await IndicatorService.get();
		commit(SET_PAD_INDICATORS, data.data);
	}
};

const mutations = {
	[SET_PAD_INDICATORS](state, data) {
		state.indicators = data;

		if (!state.padFilters.indicator) {
			state.padFilters.indicator = data[0].slug;
		}
	},
	[SET_PAD_SDGS](state, data) {
		state.sdgs = data;
	},
	[SET_PAD_SDG](state, data) {
		state.selectedSdg = data;
	},
	[SET_PAD_DATA](state, data) {
		state.data = data;
	},
	[SET_PAD_ADDITIONAL_DATA](state, data) {
		state.additionalData = data;
	},
	[SET_PAD_TITLE](state, data) {
		state.title = data;
	},
	[SET_PAD_KEYS](state, data) {
		state.keys = data;
	},
	[SET_PAD_DATA_SOURCES](state, data) {
		state.dataSources = data;
	}
};

export default {
	state,
	actions,
	getters,
	mutations
};
