import {
	FETCH_MY_ORGANISATIONS,
	FETCH_MY_ORGANISATION,
	UPDATE_ORGANISATION,
	CREATE_ORGANISATION,
	INVITE_MEMBER,
	FETCH_MEMBERS,
	REMOVE_MEMBER
} from '@/store/actions.type';

import {
	SET_CURRENT_ORGANISATION,
	SET_MY_ORGANISATIONS,
	SET_MEMBERS,
	SET_ERROR
} from '@/store/mutations.type';

import organisations from '@/data/organisation/organisations';
import organisation1 from '@/data/organisation/organisation1';
import organisation2 from '@/data/organisation/organisation2';
import organisation3 from '@/data/organisation/organisation3';

import members from '@/data/organisation/members';

import Errors from '@/helpers/Errors';

import { OrganisationService } from '@/common/organisation/organisation.service';
import { MemberService } from '@/common/organisation/member.service';

const state = {
	errors: new Errors(),
	organisations: [],
	organisation: null,
	members: []
};

const actions = {
	[CREATE_ORGANISATION](context, data) {
		return OrganisationService.post(data);
	},
	[UPDATE_ORGANISATION](context, data) {
		return OrganisationService.put(data);
	},
	async [FETCH_MY_ORGANISATIONS]({ commit }) {
		const { data } = await OrganisationService.query({ my: 'true' });
		commit(SET_MY_ORGANISATIONS, data.data);
	},
	async [FETCH_MY_ORGANISATION]({ commit }, organisation) {
		const { data } = await OrganisationService.get(organisation.slug);
		commit(SET_CURRENT_ORGANISATION, data.data);
	},
	async [FETCH_MEMBERS]({ commit, state: { organisation } }) {
		const { data } = await MemberService.get(organisation.slug);
		commit(SET_MEMBERS, data.data);
	},
	[INVITE_MEMBER]({ state: { organisation } }, form) {
		return MemberService.post(organisation.slug, form);
	},
	[REMOVE_MEMBER](context, data) {
		return MemberService.delete(data);
	}
};

const mutations = {
	[SET_MY_ORGANISATIONS](state, data) {
		state.organisations = data;
	},
	[SET_CURRENT_ORGANISATION](state, data) {
		state.organisation = data;
	},
	[SET_ERROR](state, error) {
		state.errors.record(error);
	},
	[SET_MEMBERS](state, data) {
		state.members = data;
	}
};

export default {
	state,
	actions,
	mutations
};
