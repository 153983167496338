import {APP_URL} from '@/common/config.js';

export default {
    id: 1,
    title: 'Lorem Ipsum 1',
    slug: 'first',
    description: '<h3>Lorem ipsum dolor</h3><br><p>Lorem ipsum dolor sit <b>amet</b>, consectetur adipiscing elit. <a href="https://google.com" target="_blank">Present</a> eget lectus venenatis arcu fringilla sodales non id neque. Pellentesque sit amet libero aliquet, porta erat nec, feugiat nunc. Nam auctor malesuada libero.</p><br><br>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin a suscipit nisl, ac ullamcorper tellus. In vel lorem id nulla cursus egestas. Etiam mauris lacus, mollis et maximus sed, malesuada accumsan sem. Integer non suscipit velit, et tincidunt nunc. Donec libero ante, condimentum luctus lacus non, tempor pulvinar arcu. Sed semper efficitur eros, non euismod lorem ultricies vel. Proin leo nisl, tristique at dignissim vitae, vulputate eget augue.',
    timeline: {
        from: '2020-12-17T15:46:59.034373+02:00',
        to: '2021-02-17T15:46:59.034373+02:00'
    },
    process: {
        name: 'The Budget Cycle',
        slug: 'the-budget-cycle'
    },
    sector: {
        id: 1,
        name: 'Labour',
        slug: 'labour',
        color: '#023728'
    },
    keyContacts: [
        {
            name: 'Institution name 1',
            slug: 'institution-name-1',
            link: 'https://google.com'
        },
        {
            name: 'Institution name 2',
            slug: 'institution-name-2',
            link: 'https://google.com'
        },
        {
            name: 'Institution name 3',
            slug: 'institution-name-3',
            link: 'https://google.com'
        },
        {
            name: 'Institution name 4',
            slug: 'institution-name-4',
            link: 'https://google.com'
        }
    ],
    resources: [
        {
            name: 'View resource',
            type: 'internal', //types: internal, external, download
            link: `${APP_URL}/engaging-with-government/systems-and-policies?active=koko-networks-africa`
        },
        {
            name: 'View Africa Practise RES',
            type: 'download',
            link: `${APP_URL}/img/sh-klogos.95bcf679.png`
        },
    ],
}
