<template>
	<div class="button--popup__card" v-click-outside="handleClickOutside">
		<img
			src="@/assets/imgs/social/facebook__green.svg"
			alt="facebook"
			@click="share('facebook')"
		/>
		<img
			src="@/assets/imgs/social/twitter__green.svg"
			alt="twitter"
			@click="share('twitter')"
		/>
		<img
			src="@/assets/imgs/social/linkedin__green.svg"
			alt="linkedin"
			@click="share('linkedin')"
		/>
		<img
			src="@/assets/imgs/social/email__green.svg"
			alt="email"
			@click="share('email')"
		/>
	</div>
</template>

<script>
export default {
	props: {
		link: {
			type: String,
			default: null
		}
	},
	methods: {
		handleClickOutside() {
			this.$emit('onClickOutside');
		},
		share(type) {
			const link = this.link ? this.link : location.href;
			let window_size = 'width=585,height=511';
			let url;

			switch (type) {
				case 'facebook':
					url = `http://www.facebook.com/sharer/sharer.php?u=${link}`;
					break;
				case 'twitter':
					url = `http://www.twitter.com/intent/tweet?url=${link}`;
					break;
				case 'linkedin':
					url = `https://www.linkedin.com/shareArticle?mini=true&url=${link}`;
					break;
				case 'email':
					url = `mailto:info@example.com?body=${link}`;
					break;
			}
			window.open(
				url,
				'',
				'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,' + window_size
			);
		}
	}
};
</script>

<style scoped>
.social-link {
	padding: 0;
	margin-right: 0 !important;
}
</style>
