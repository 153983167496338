import ApiService from '@/common/api.service';

export const PaiService = {
    get(params) {
        return ApiService.query('/pai', params);
    },
    download(form) {
        return ApiService.post('/pai/download', form);
    }
};
