<template>
	<VMultiselect
		trackBy="id"
		:value="value"
		:options="specialisms"
		@input="$emit('input', $event)"
	/>
</template>

<script>
import { mapState } from 'vuex';
import { FETCH_SPECIALISMS } from '@/store/actions.type';

export default {
	props: {
		value: {}
	},
	computed: {
		...mapState({
			specialisms: state => state.app.specialisms
		})
	},
	mounted() {
		this.fetchSpecialisms();
	},
	methods: {
		fetchSpecialisms() {
			this.$store.dispatch(FETCH_SPECIALISMS);
		}
	}
};
</script>
