<template>
	<div id="app">
		<VLoader />

		<Header />

		<transition name="fade" mode="out-in">
			<router-view />
		</transition>

        <SubFooter v-if="showSubfooter" />
        <Footer />
        <Alert />

        <component :is="activeModalFile" v-if="activeModal"></component>
	</div>
</template>

<script>
import {
	SET_ACTIVE_MODAL,
	INITIALISE_VIEW_WELCOME_MODAL
} from '@/store/mutations.type';

import { mapGetters, mapState } from 'vuex';

import Header from '@/components/layout/TheHeader';
import Footer from '@/components/layout/TheFooter';
import SubFooter from '@/components/layout/TheSubFooter';

export default {
	name: 'App',
	components: {
		SubFooter,
		Header,
		Footer
	},
	computed: {
		...mapGetters([
            'loading',
            'activeModalFile'
        ]),
		...mapState({
			activeModal: state => state.app.activeModal,
			viewWelcomeModal: state => state.app.viewWelcomeModal
		}),
		showSubfooter() {
			const hideOnPaths = ['account', 'reset-password'];
			return !hideOnPaths.some(path => this.$route.path.includes(path));
		}
    },
	beforeCreate() {
		this.$store.commit(INITIALISE_VIEW_WELCOME_MODAL);
	},
	mounted() {
		this.setWelcomeModal();
	},
	methods: {
		setWelcomeModal() {
			if (this.viewWelcomeModal) return;
			this.$store.commit(SET_ACTIVE_MODAL, 'WelcomeModal');
		}
	}
};
</script>
