import Vue from 'vue';
import NProgress from 'nprogress';
import VueRouter from 'vue-router';

import jwtService from '@/common/jwt.service';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'landing',
		redirect: '/engaging-with-government',
		component: () => import('@/pages/LandingPage'),
		children: [
			{
				path: '/policy-advocacy-index',
				name: 'policy-advocacy-index',
				component: () => import('@/pages/LandingPage/sections/PolicyAdvocacyIndex')
			},
			{
				path: '/engaging-with-government',
				name: 'engaging-with-government',
				redirect: '/engaging-with-government/systems-and-policies',
				component: () => import('@/pages/LandingPage/sections/EngagingWithGovernment'),
				children: [
					{
						path: 'engagement-windows',
						name: 'engagement-windows',
						component: () => import('@/pages/LandingPage/sections/EngagingWithGovernment/EngagementWindows')
					},
					{
						path: 'systems-and-policies',
						name: 'systems-and-policies',
						component: () => import('@/pages/LandingPage/sections/EngagingWithGovernment/SystemsAndPolicies')
					}
				]
			},
			{
				path: '/policy-advocacy-data-explorer',
				name: 'policy-advocacy-data-explorer',
				component: () => import('@/pages/LandingPage/sections/PolicyAdvocacyDataExplorer')
			}
		]
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/pages/AboutPage')
	},
	{
		path: '/policy-advocacy-index/preview',
		name: 'pai-preview',
		component: () => import('@/pages/PAIPreview')
	},
	{
		path: '/policy-advocacy-data-explorer/preview',
		name: 'pad-preview',
		component: () => import('@/pages/PADPreview')
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: () => import('@/pages/PrivacyPolicyPage')
	},
	{
		path: '/terms',
		name: 'terms',
		component: () => import('@/pages/TermsPage')
	},
	{
		path: '/organisation-profile/:slug',
		name: 'organisation-profile',
		component: () => import('@/pages/OrganisationProfilePage')
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: () => import('@/pages/ResetPasswordPage')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('@/pages/ContactUsPage/index')
	},
	{
		path: '/knowledgebase',
		name: 'knowledgebase',
		component: () => import('@/pages/FaqPage')
	},
	{
		path: '/community',
		name: 'community',
		component: () => import('@/pages/CommunityPage')
	},
	{
		path: '/redirect',
		name: 'redirect',
		component: () => import('@/pages/RedirectPage')
	},
	{
		path: '/account',
		name: 'account',
		beforeEnter: authGuard,
		redirect: '/account/profile',
		component: () => import('@/pages/AccountPage'),
		children: [
			{
				path: 'profile',
				name: 'profile',
				component: () => import('@/pages/AccountPage/Profile/index')
			},
			{
				path: 'organisation',
				name: 'organisation',
				component: () => import('@/pages/AccountPage/YourOrganisation/index')
			},
			{
				path: 'visuals',
				name: 'visuals',
				component: () => import('@/pages/AccountPage/SavedVisualsAndViews')
			}
		]
	},
	{
		path: '*',
		name: 'not-found',
		component: () => import('@/pages/PageNotFound')
	}
];

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior(to) {
		const scrollOffPaths = [
			'knowledgebase',
			'engagement-windows',
			'systems-and-policies',
			'policy-advocacy-index',
			'engaging-with-government',
			'policy-advocacy-data-explorer'
		];

		const shouldScroll = !scrollOffPaths.some(path => to.name.includes(path));

		if (shouldScroll) {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			});
		}
	}
});

router.beforeResolve((to, from, next) => {
	if (to.name) {
		NProgress.start();
	}
	next();
});

router.afterEach(() => {
	NProgress.done();
});

export default router;

function authGuard(to, from, next) {
	var isAuthenticated = jwtService.getToken();

	if (isAuthenticated) {
		next();
	} else {
		next({ path: from.path });
	}
}
