<template>
	<div class="org-section">
		<div class="org-section__inner gr gr--wrap">
			<div class="col col--1@tablet hide show@tablet"></div>

			<div class="col col--12 col--10@tablet">
				<VHeading level="3" screen="mobile">
					Registered organisations
				</VHeading>
				<VText size="small" classes="org-section__inner__subtitle">
					<div v-html="content"></div>
				</VText>

				<ListView v-if="isAuthenticated" :top="true"></ListView>

				<div v-if="isAuthenticated" class="org-section__action">
					<VButton
						size="big"
						color="outline-black"
						route="/community"
						:uppercase="true"
					>
						View all registered organisations
					</VButton>
				</div>

				<div class="org-section__cta" @click="scrollToTop">
					<router-link
						tag="div"
						:to="{ name: 'knowledgebase' }"
						class="org-section__cta__item cursor-pointer"
						active-class="active"
					>
						<VText size="bigger" align="center">
							View our knowledgebase
						</VText>
					</router-link>

					<div
						class="org-section__cta__item cursor-pointer mobile-bg"
						@click.stop="$store.commit('setActiveModal', 'RegisterModal')"
					>
						<VText size="bigger" align="center">
							Register for your free <br />
							Takwimu account
						</VText>
					</div>
				</div>
			</div>

			<div class="col col--1@tablet hide show@tablet"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import content from '@/mixins/content';
import scrollToTop from '@/mixins/scroll-to-top';
import ListView from '@/pages/CommunityPage/ListView';

export default {
	name: 'SubFooter',
	mixins: [content, scrollToTop],
	components: {
		ListView
	},
	computed: {
		...mapGetters(['isAuthenticated'])
	},
	created() {
		this.fetchContent('registered-organisations');
	}
};
</script>
