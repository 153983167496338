import {
    SET_EW_FILTERS,
	SET_EW_PROCESSES,
	SET_EW_SUBPROCESS,
	SET_EW_SUBPROCESSES
} from '@/store/mutations.type';
import {
	FETCH_EW_PROCESSES,
	FETCH_EW_SUBPROCESS,
	FETCH_EW_SUBPROCESSES
} from '@/store/actions.type';

import { ProcessService } from '@/common/ewg/engagement-windows/process.service';
import { SubprocessService } from '@/common/ewg/engagement-windows/subprocess.service';

import subprocesses from '@/data/ewg/engagement-windows/subprocesses';
import subprocess from '@/data/ewg/engagement-windows/subprocess';
import processes from '@/data/ewg/engagement-windows/processes';

const state = {
	processes: [],
	subprocesses: [],
	subprocess: null,
	ewFilters: {
		sector: null,
		process: null
	}
};

const actions = {
	async [FETCH_EW_PROCESSES]({ commit }) {
		const { data } = await ProcessService.query();
		commit(SET_EW_PROCESSES, data);
	},
	async [FETCH_EW_SUBPROCESSES]({ commit }, params = {}) {
		commit(SET_EW_SUBPROCESSES, []);
		const { data } = await SubprocessService.query(params);
		commit(SET_EW_SUBPROCESSES, data.data);
	},
	async [FETCH_EW_SUBPROCESS]({ commit }, shortSubprocess) {
		// ToDo replace with slug when API done
		const { data } = await SubprocessService.get(shortSubprocess.id);
		commit(SET_EW_SUBPROCESS, data.data);
	}
};

const mutations = {
	[SET_EW_PROCESSES](store, data) {
		store.processes = data;
	},
	[SET_EW_FILTERS](store, data) {
		store.processes = data;
	},
	[SET_EW_SUBPROCESSES](store, data) {
		store.subprocesses = data;
	},
	[SET_EW_SUBPROCESS](store, data) {
		store.subprocess = data;
	}
};

export default {
    state,
	actions,
	mutations
};
