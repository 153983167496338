<template>
	<div key="socials" class="item item--no-center" v-if="showSocials">
		<VText size="small" weight="bold" classes="item__label">
			Social
		</VText>
		<VText size="small" classes="item__value">
			<div
				key="twitter"
				class="item__value--social"
				v-if="organisation.socials.twitter"
			>
				<img src="@/assets/imgs/social/twitter__green.svg" alt="twitter icon" />
				<VLink
					size="small"
					target="_blank"
					:href="organisation.socials.twitter"
				>
					Twitter
				</VLink>
			</div>
			<div
				key="facebook"
				class="item__value--social"
				v-if="organisation.socials.facebook"
			>
				<img
					src="@/assets/imgs/social/facebook__green.svg"
					alt="facebook icon"
				/>
				<VLink
					size="small"
					target="_blank"
					:href="organisation.socials.facebook"
				>
					Facebook
				</VLink>
			</div>
			<div
				key="instagram"
				class="item__value--social"
				v-if="organisation.socials.instagram"
			>
				<img
					src="@/assets/imgs/social/instagram__green.svg"
					alt="instagram icon"
				/>
				<VLink
					size="small"
					target="_blank"
					:href="organisation.socials.instagram"
				>
					Instagram
				</VLink>
			</div>
			<div
				v-if="organisation.socials.linkedin"
				key="linkedin"
				class="item__value--social"
			>
				<img
					src="@/assets/imgs/social/linkedin__green.svg"
					alt="linkedin icon"
				/>
				<VLink
					size="small"
					target="_blank"
					:href="organisation.socials.linkedin"
				>
					Linkedin
				</VLink>
			</div>
		</VText>
		<br />
	</div>
</template>

<script>
export default {
	props: {
		organisation: {
			type: Object,
			required: true
		}
    },
	computed: {
		showSocials() {
			const {
				facebook,
				twitter,
				linkedin,
				instagram
			} = this.organisation.socials;

			return facebook || twitter || linkedin || instagram;
		}
	},
};
</script>
