<template>
	<div id="overlay" v-if="loading" @click.stop.prevent>
		<div class="loader">
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
			<div class="dot"></div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'Loader',
	computed: {
		...mapGetters(['loading'])
	}
};
</script>

<style>
.loader {
	position: absolute;
	top: 50%;
	left: 40%;
	margin-left: 10%;
	transform: translate3d(-50%, -50%, 0);
}
.dot {
	width: 24px;
	height: 24px;
	background: #023728;
	border-radius: 100%;
	display: inline-block;
	animation: slide 1s infinite;
}
.dot:nth-child(1) {
	animation-delay: 0.1s;
	background: #036b4d;
}
.dot:nth-child(2) {
	animation-delay: 0.2s;
	background: #23866e;
}
.dot:nth-child(3) {
	animation-delay: 0.3s;
	background: #58a18e;
}
.dot:nth-child(4) {
	animation-delay: 0.4s;
	background: #87bbaf;
}
.dot:nth-child(5) {
	animation-delay: 0.5s;
	background: #9dbeb7;
}
@-moz-keyframes slide {
	0% {
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(2);
	}
	100% {
		transform: scale(1);
	}
}
@-webkit-keyframes slide {
	0% {
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(2);
	}
	100% {
		transform: scale(1);
	}
}
@-o-keyframes slide {
	0% {
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(2);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes slide {
	0% {
		transform: scale(1);
	}
	50% {
		opacity: 0.3;
		transform: scale(2);
	}
	100% {
		transform: scale(1);
	}
}
</style>
