import ApiService from '@/common/api.service';

export const SocialService = {
    post(form) {
        return ApiService.post('/oauth', form);
    },
    getLinkedin(form) {
        return ApiService.post('/linkedin', form);
    }
};
