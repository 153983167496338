export default {
    methods: {
		scrollToTop() {
			this.$nextTick(() => {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});
			});
		}
	}
}