import ApiService from '@/common/api.service';

export const AuthService = {
    logout() {
		return ApiService.query('/logout');
	},
	login(params) {
		return ApiService.post('/login', params);
    },	
	register(params) {
		return ApiService.post('/register', params);
	},
	recoverPassword(params) {
		return ApiService.post('/password-reset', params);
	},
	changePassword({ uid, token, form }) {
		return ApiService.post(`password-change/${uid}/${token}`, form);
	}
};
