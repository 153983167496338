import ApiService from '@/common/api.service';

export const PreferenceService = {
    get() {
        return ApiService.query('/preference')
    },
    put(form) {
        return ApiService.put('/preference', form)
    }
};
