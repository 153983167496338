<template>
	<div class="tab-section">
		<div class="hide show@max-tablet" v-if="$slots.header">
			<div class="tab-section__mobile gr gr--wrap">
				<div class="col col--12@max-tablet">
					<slot name="header" />
				</div>
			</div>
		</div>

		<div class="tab-section__inner gr gr--wrap">
			<div class="col col--1@tablet hide show@tablet"></div>

			<div class="col col--12 col--10@tablet">
				<div class=" hide show@tablet">
					<div class="tab-section__tab">
						<router-link
							v-for="tab in tabs"
							:key="tab.route"
							tag="div"
							active-class="active"
							class="tab-section__tab__item"
							:to="{ name: tab.route }"
						>
							{{ tab.name }}
						</router-link>
					</div>
				</div>

				<slot></slot>
			</div>
			<div class="col col--1@tablet hide show@tablet"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Tabs',
	props: {
		tabs: {
			type: Array,
			default: () => []
		}
	}
};
</script>
