import {
    FETCH_FAQS,
    FETCH_FAQ_CATEGORIES,
    LEFT_FAQ_FEEDBACK,
    FETCH_FAQ_TAGS,
    LEFT_IS_FAQ_HELPFUL
} from '../actions.type';

import {
    SET_FAQS,
    SET_FAQ_CATEGORIES,
    SET_FAQ_TAGS
} from '../mutations.type';

import faqs from '@/data/faq/faqs';
import categories from '@/data/faq/categories';
import tags from '@/data/faq/tags';

import {FaqService} from '@/common/faq/faq.service';
import {FaqCategoryService} from '@/common/faq/category.service';
import {FaqFeedbackService} from '@/common/faq/feedback.service';
import {FaqTagService} from '@/common/faq/tag.service';

const state = {
    faqs: [],
    categories: [],
    tags: []
};

const actions = {
    async [FETCH_FAQS](context, params) {
        const { data } = await FaqService.query(params);
        context.commit(SET_FAQS, data.data);
    },
    async [FETCH_FAQ_CATEGORIES](context) {
        const { data } = await FaqCategoryService.get();
        context.commit(SET_FAQ_CATEGORIES, data.data);
    },
    async [FETCH_FAQ_TAGS](context) {
        const { data } = await FaqTagService.get();
        context.commit(SET_FAQ_TAGS, data.data);
    },
    async [LEFT_FAQ_FEEDBACK](context, form) {
        await FaqFeedbackService.post(form.faq, { feedback: form.feedback });
    },
    async [LEFT_IS_FAQ_HELPFUL](context, form) {
        await FaqFeedbackService.postIsHelpful(form.faq, form.isHelpful);
    },
};


const mutations = {
    [SET_FAQS](state, data) {
        state.faqs = data;
    },
    [SET_FAQ_CATEGORIES](state, data) {
        state.categories = data;
    },
    [SET_FAQ_TAGS](state, data) {
        state.tags = data;
    },
};

export default {
    state,
    actions,
    mutations
};
