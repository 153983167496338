export default [
    {
        id: 1,
        name: 'ODA estimate',
        slug: 'oda',
        color: '#87bbaf'
    },
    {
        id: 2,
        name: 'All other financing',
        slug: 'other',
        color: '#23866e'
    }
];
