<template>
  <div class="navbar">
    <div class="navbar__primary">
      <div class="navbar__inner gr gr--wrap gr--flex">
        <div class="col col--1@tablet col--6">
          <div class="navbar__inner__image cursor-pointer">
            <img
                src="@/assets/imgs/logo/logo-vertical.png"
                class="hide show@tablet"
                alt="Takwimu logo"
            />
            <img
                src="@/assets/imgs/logo/logo.png"
                class="hide show@max-tablet"
                alt="Takwimu logo"
            />
          </div>
        </div>
        <div
            class="col hide show@max-tablet col--6@max-tablet navbar__inner__mobile"
        >
          <VText
              size="smaller"
              :uppercase="true"
              classes="cursor-pointer"
              @click="menu = true"
          >
            menu
            <img
                src="@/assets/imgs/icons/icon-menu.svg"
                class="cursor-pointer"
                alt=""
            />
          </VText>
          <div
              class="navbar__inner__menu"
              :class="{ 'menu--open': menu }"
              v-if="menu"
          >
            <div class="navbar__inner__menu__header">
              <div
                  class="navbar__inner__menu__header__left"
                  v-if="isAuthenticated"
              >
                <div v-if="currentUser.initialPhoto" class="navbar__register__card__avatar mobile--avatar">
                  <img :src="currentUser.initialPhoto"/>
                </div>
                <VHeading level="5">Hello {{ currentUser.firstName }}</VHeading>
              </div>

              <div class="navbar__inner__menu__header__left" v-else>
                <div>
                  <VHeading level="5">Hello</VHeading>
                </div>
              </div>
              <div class="navbar__inner__menu__close" @click="menu = false">
                <img src="@/assets/imgs/icons/icon-close.svg" alt=""/>
              </div>
            </div>
            <div
                class="navbar__inner__menu__list navbar__inner__menu__list-items"
            >
              <div>
                <VLink
                    route="/"
                    :classes="dashboardRouteActive ? 'active' : ''"
                    @click="menu = false"
                >
                  Dashboard
                </VLink>
              </div>
              <div>
                <VLink
                    route="/community"
                    :classes="$route.path.includes('community') ? 'active' : ''"
                    @click="menu = false"
                >
                  Community
                </VLink>
              </div>
              <div>
                <VLink
                    route="/about"
                    :classes="$route.path.includes('about') ? 'active' : ''"
                    @click="menu = false"
                >
                  About us
                </VLink>
              </div>
              <div>
                <VLink
                    route="/knowledgebase"
                    :classes="
										$route.path.includes('knowledgebase') ? 'active' : ''
									"
                    @click="menu = false"
                >
                  Knowledgebase
                </VLink>
              </div>
              <div>
                <VLink
                    route="/contact"
                    :classes="$route.path.includes('contact') ? 'active' : ''"
                    @click="menu = false"
                >
                  Contact us
                </VLink>
              </div>
              <div v-if="isAuthenticated">
                <VLink
                    route="/account"
                    :classes="$route.path.includes('account') ? 'active' : ''"
                    @click="menu = false"
                >
                  Your Account
                </VLink>
              </div>
            </div>
            <div>
              <div v-if="!isAuthenticated" class="navbar__inner__menu__action">
                <VButton
                    color="black"
                    @click.stop="
										menu = false;
										$store.commit('setActiveModal', 'RegisterModal');
									"
                >
                  Register
                </VButton>

                <VButton
                    color="outline-black"
                    @click.stop="
										menu = false;
										$store.commit('setActiveModal', 'LoginModal');
									"
                >
                  Login
                </VButton>
              </div>
              <div v-else class="navbar__inner__menu__action">
                <VButton
                    color="outline-black"
                    route="/account/profile"
                    @click="menu = false"
                >
                  Manage your profile
                </VButton>
                <VButton
                    color="outline-black"
                    route="{name: '/account/visuals'}"
                    @click="menu = false"
                >
                  Your saved visuals
                </VButton>
                <VLink size="small" @click.stop="logout" @click="menu = false">
                  logout
                </VLink>
              </div>
            </div>
          </div>
        </div>

        <div class="col hide show@tablet col--8@tablet" style="display: flex">
          <div>
            <VHeading level="1">
              Supporting African <br/>
              human development policy
            </VHeading>
            <VText size="small" classes="navbar__inner__description">
              A data visualisation and engagement toolkit for CSOs and their
              development partners.
            </VText>
          </div>
          <div>
            <img
                src="@/assets/imgs/logo/beta-launch.png"
                class="beta-icon"
                alt="Beta"
            />
          </div>
        </div>
        <div class="col hide show@tablet col--3@tablet">
          <div class="navbar__register">
            <div v-if="!isAuthenticated" class="navbar__register__card">
              <div class="navbar__register__card__header">
                <VHeading level="5">Hello!</VHeading>
              </div>
              <VText size="small">
                Why not
                <VLink
                    size="small"
                    @click.stop="$store.commit('setActiveModal', 'RegisterModal')"
                >
                  register
                </VLink>
                or
                <VLink
                    size="small"
                    @click.stop="$store.commit('setActiveModal', 'LoginModal')"
                >
                  login
                </VLink>
                and take advantage of all
                <u
                    class="cursor-pointer"
                    @click.stop="$store.commit('setActiveModal', 'WelcomeModal')"
                >
                  Takwimu’s benefits
                </u>?
              </VText>
              <VButton
                  color="black"
                  @click.stop="$store.commit('setActiveModal', 'RegisterModal')"
              >
                Register
              </VButton>

              <VButton
                  color="outline-black"
                  @click.stop="$store.commit('setActiveModal', 'LoginModal')"
              >
                Login
              </VButton>
            </div>
            <div
                v-else
                class="navbar__register__card navbar__register__card--auth"
            >
              <div class="navbar__register__card__header">
                <div class="navbar__register__card__avatar user--avatar">
                  <img :src="currentUser.initialPhoto"/>
                </div>
                <VHeading level="5">Hello {{ currentUser.firstName }}</VHeading>
              </div>
              <VButton color="outline-black" route="/account/profile">
                Manage your profile
              </VButton>
              <VButton color="outline-black" route="/account/visuals">
                Your saved visuals
              </VButton>
              <VLink size="small" @click.stop="logout">
                logout
              </VLink>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="navbar__secondary hide show@tablet">
      <div class="navbar__secondary__inner gr gr--wrap gr--flex">
        <div class="col col--1@tablet"></div>
        <div class="col col--11@tablet">
          <ul class="ul ul--inline navbar__secondary__nav">
            <router-link
                tag="li"
                :to="{ name: 'landing' }"
                :class="{ active: dashboardRouteActive }"
                active-class="active"
                exact
            >
							<span class="lg link--no-underline">
								Dashboard
							</span>
            </router-link>
            <router-link
                tag="li"
                :to="{ name: 'community' }"
                active-class="active"
            >
							<span class="lg link--no-underline">
								Community
							</span>
            </router-link>
            <router-link tag="li" :to="{ name: 'about' }" active-class="active">
							<span class="lg link--no-underline">
								About us
							</span>
            </router-link>
            <router-link
                tag="li"
                :to="{ name: 'knowledgebase' }"
                active-class="active"
            >
							<span class="lg link--no-underline">
								Knowledgebase
							</span>
            </router-link>
            <router-link
                tag="li"
                :to="{ name: 'contact' }"
                active-class="active"
            >
							<span class="lg link--no-underline">
								Contact us
							</span>
            </router-link>
            <router-link
                tag="li"
                :to="{ name: 'account' }"
                active-class="active"
                v-if="isAuthenticated"
            >
							<span class="lg link--no-underline">
								Your Account
							</span>
            </router-link>
          </ul>
        </div>
      </div>
    </div>
    <portal-target name="pai"></portal-target>
    <portal-target name="pad"></portal-target>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {FETCH_PROFILE, LOGOUT} from '@/store/actions.type';
import VLink from '../ui/VLink';

export default {
  name: 'Header',
  components: {VLink},
  data() {
    return {
      menu: false
    };
  },
  watch: {
    'isAuthenticated': 'fetchProfile'
  },
  computed: {
    ...mapState({
      currentUser: state => state.profile.profile
    }),
    ...mapGetters(['isAuthenticated']),
    dashboardRouteActive() {
      const dashboardPaths = [
        'policy-advocacy-index',
        'engaging-with-government',
        'policy-advocacy-data-explorer'
      ];

      return dashboardPaths.some(path => this.$route.path.includes(path));
    }
  },
  mounted() {
    this.fetchProfile()
  },
  methods: {
    async logout() {
      await this.$store.dispatch(LOGOUT);
      this.$router.push('/');
    },
    fetchProfile() {
      if (this.isAuthenticated) {
        this.$store.dispatch(FETCH_PROFILE);
      }
    }
  }
};
</script>
