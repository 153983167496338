export default [
	{
		name: 'End Poverty',
		slug: 'End Poverty',
		imageSlug: 'end-poverty',
		description: '',
		order: 1,
		subgoals: [
			{
				order: 1,
				name: 'Poverty headcount ratio at $1.90/day(%)',
				slug: 'Poverty headcount ratio at $1.90/day(%)',
				description:
					'Estimated percentage of the population that is living under the poverty threshold of US$1.90 a day. Estimated using historical estimates of the income distribution, projections of population changes by age and educational attainment, and GDP projections.'
			},
			{
				order: 2,
				name: 'Proportion of population living below the national poverty line',
				slug: 'Proportion of population living below the national poverty line',
				description:
					'The percentage of the total population living below the national poverty line'
			},
			{
				order: 3,
				name: 'Population covered by Social Protection(%)',
				slug: 'Population covered by Social Protection(%)',
				description:
					'Percentage of population participating in social protection and labor programs (includes direct and indirect beneficiaries). The indicator is estimated by program type, for the entire population and by quintiles of both the post-transfer and pre-transfer welfare distribution. Programs are aggregated into social assistance, social insurance and labor market according to ASPIRE classification. Indicators for all SPL programs provide the totals summing up the social assistance, social insurance and labor market figures. Specifically, coverage is (number of individuals in the quintile who live in a household where at least one member receives the transfer)/(number of individuals in that quintile).'
			}
		]
	},
	{
		name: 'Zero Hunger',
		slug: 'Zero Hunger',
		imageSlug: 'zero-hunger',
		description: '',
		order: 2,
		subgoals: [
			{
				order: 1,
				name: 'Prevalence of undernourishment(%)',
				slug: 'Prevalence of undernourishment(%)',
				description:
					'The percentage of the population whose food intake is insufficient to meet dietary energy requirements for a minimum of one year. Dietary energy requirements are defined as the amount of dietary energy required by an individual to maintain body functions, health and normal activity. FAO et al. (2015) report 14.7 million undernourished people in developed regions, which corresponds to an average prevalence of 1.17% in the developed regions. We assumed a 1.2% prevalence rate for each high-income country (World Bank, 2019) with missing data.'
			},
			{
				order: 2,
				name:
					'Prevalence of stunting in children(% of population aged under 5)',
				slug:
					'Prevalence of stunting in children(% of population aged under 5)',
				description:
					'The percentage of children up to the age of 5 years that are stunted, measured as the percentage that fall below minus two standard deviations from the median height for their age, according to the WHO Child Growth Standards. UNICEF et al. (2016) report an average prevalence of wasting in high-income countries of 2.58%. We assumed this value for high-income countries with missing data.'
			},
			{
				order: 3,
				name: 'Prevalence of wasting in children(% of population aged under 5)',
				slug: 'Prevalence of wasting in children(% of population aged under 5)',
				description:
					'The percentage of children up to the age of 5 years whose weight falls below minus two standard deviations from the median weight for their age, according to the WHO Child Growth Standards. UNICEF et al. (2016) report an average prevalence of wasting in high-income countries of 0.75%. We assumed this value for high-income countries with missing data.'
			},
			{
				order: 4,
				name: 'Prevalence of obesity, BMI ≥ 30(% of adult population)',
				slug: 'Prevalence of obesity, BMI ≥ 30(% of adult population)',
				description:
					'The percentage of the adult population that has a body mass index (BMI) of 30kg/m¬≤ or higher, based on measured height and weight.'
			},
			{
				order: 5,
				name: 'Cereal yield(tonnes per hectare of harvested land)',
				slug: 'Cereal yield(tonnes per hectare of harvested land)',
				description:
					'Cereal yield, measured as tonnes per hectare of harvested land. Production data on cereals relate to crops harvested for dry grain only and excludes crops harvested for hay or green for food, feed, or silage and those used for grazing.'
			},
			{
				order: 6,
				name: 'Fertilizer consumption(kg per hectare of arable land)',
				slug: 'Fertilizer consumption(kg per hectare of arable land)',
				description:
					'The quantity of plant nutrients used per unit of arable land'
			}
		]
	},
	{
		name: 'Good Health and Well-Being',
		slug: 'Good Health and Well-Being',
		imageSlug: 'good-health-and-well-being',
		description: '',
		order: 3,
		subgoals: [
			{
				order: 1,
				name: 'Maternal mortality rate(per 100,000 live births)',
				slug: 'Maternal mortality rate(per 100,000 live births)',
				description:
					'The estimated number of women, between the age of 15-49, who die from pregnancy-related causes while pregnant or within 42 days of termination of pregnancy, per 100,000 live births.'
			},
			{
				order: 2,
				name: 'Births attended by skilled health personnel(%)',
				slug: 'Births attended by skilled health personnel(%)',
				description:
					'The percentage of births attended by personnel trained to give the necessary supervision, care, and advice to women during pregnancy, labor, and the postpartum period, to conduct deliveries on their own, and to care for newborns.'
			},
			{
				order: 3,
				name: 'Neonatal mortality rate(per 1,000 live births)',
				slug: 'Neonatal mortality rate(per 1,000 live births)',
				description:
					'The number of newborn infants (neonates) who die before reaching 28 days of age, per 1,000 live births.'
			},
			{
				order: 4,
				name: 'Mortality rate, under-5(per 1,000 live births)',
				slug: 'Mortality rate, under-5(per 1,000 live births)',
				description:
					'The probability that a newborn baby will die before reaching age five, if subject to age-specific mortality rates of the specified year, per 1,000 live births.'
			},
			{
				order: 5,
				name: 'New HIV infections(per 1,000 uninfected population)',
				slug: 'New HIV infections(per 1,000 uninfected population)',
				description:
					'Number of people newly infected with HIV per 1,000 uninfected population.'
			},
			{
				order: 6,
				name: 'People living with HIV receiving antiretroviral therapy(%)',
				slug: 'People living with HIV receiving antiretroviral therapy(%)',
				description:
					'The percentage of people on antiretroviral therapy among all people living with HIV'
			},
			{
				order: 7,
				name: 'Incidence of tuberculosis(per 100,000 population)',
				slug: 'Incidence of tuberculosis(per 100,000 population)',
				description:
					'The estimated rate of new and relapse cases of tuberculosis in a given year, expressed per 100,000 people. All forms of tuberculosis are included, including cases of people living with HIV.'
			},
			{
				order: 8,
				name:
					'Proportion of children under 5 with fever who are treated with appropriate anti-malarial drugs(%)',
				slug:
					'Proportion of children under 5 with fever who are treated with appropriate anti-malarial drugs(%)',
				description:
					'The percentage of children aged 0-59 months who were ill with a fever in the two weeks before the survey and who received any anti-malarial drugs during that time.'
			},
			{
				order: 9,
				name: 'Malaria mortality rate',
				slug: 'Malaria mortality rate',
				description:
					'Number of adults and children who have died due to malaria in a specific year, expressed as a rate per 100 000 population.'
			},
			{
				order: 10,
				name:
					'Coverage of Preventive Chemotherapy for Neglected Tropical Diseases(%)',
				slug:
					'Coverage of Preventive Chemotherapy for Neglected Tropical Diseases(%)',
				description:
					'Coverage is calculated as the number of people in need of PC and treated out of population requiring PC.'
			},
			{
				order: 11,
				name:
					'Age-standardized death rate due to cardiovascular disease, cancer, diabetes, and chronic respiratory disease in populations age 30–70 years(per 100,000 population)',
				slug:
					'Age-standardized death rate due to cardiovascular disease, cancer, diabetes, and chronic respiratory disease in populations age 30–70 years(per 100,000 population)',
				description:
					'The probability of dying between the ages of 30 and 70 years from cardiovascular diseases, cancer, diabetes or chronic respiratory diseases, defined as the percent of 30-year-old-people who would die before their 70th birthday from these diseases, assuming current mortality rates at every age and that individuals would not die from any other cause of death (e.g. injuries or HIV/AIDS).'
			},
			{
				order: 12,
				name: 'Traffic deaths(per 100,000 population)',
				slug: 'Traffic deaths(per 100,000 population)',
				description:
					'Estimated number of fatal road traffic injuries per 100,000 people.'
			},
			{
				order: 13,
				name: 'Adolescent fertility rate(births per 1,000 women aged 15 to 19)',
				slug: 'Adolescent fertility rate(births per 1,000 women aged 15 to 19)',
				description:
					'The number of births per 1,000 women between the age of 15 to 19.'
			},
			{
				order: 14,
				name:
					'Universal health coverage(UHC) service coverage index(worst 0-100 best)',
				slug:
					'Universal health coverage(UHC) service coverage index(worst 0-100 best)',
				description:
					'Coverage of essential health services (defined as the average coverage of essential services based on tracer interventions that include reproductive, maternal, newborn and child health, infectious diseases, non-communicable diseases and service capacity and access, among the general and the most disadvantaged population). The indicator is an index reported on a unitless scale of 0 to 100, which is computed as the geometric mean of 14 tracer indicators of health service coverage.'
			},
			{
				order: 15,
				name:
					'Age-standardized death rate attributable to household air pollution and ambient air pollution(per 100,000 population)',
				slug:
					'Age-standardized death rate attributable to household air pollution and ambient air pollution(per 100,000 population)',
				description:
					'Mortality rate that is attributable to the joint effects of fuels used for cooking indoors and ambient outdoor air pollution.'
			},
			{
				order: 16,
				name:
					'Percentage of surviving infants who received 2 WHO-recommended vaccines(%)',
				slug:
					'Percentage of surviving infants who received 2 WHO-recommended vaccines(%)',
				description:
					'Estimated national routine immunization coverage of infants, expressed as the percentage of surviving infants children under the age of 12 months who received two WHO-recommended vaccines (3rd dose of DTP and 1st dose of measles). Calculated as the minimum value between the percentage of infants who have received the 3rd dose of DTP and the percentage who have received the 1st dose of measles.'
			},
			{
				order: 17,
				name: 'Life expectancy at birth(years)',
				slug: 'Life expectancy at birth(years)',
				description:
					'The average number of years that a newborn could expect to live, if he or she were to pass through life exposed to the sex- and age-specific death rates prevailing at the time of his or her birth, for a specific year, in a given country, territory, or geographic area.'
			},
			{
				order: 18,
				name: 'Subjective Wellbeing(average ladder score, worst 0-10 best)',
				slug: 'Subjective Wellbeing(average ladder score, worst 0-10 best)',
				description:
					'Subjective self-evaluation of life, where respondents are asked to evaluate where they feel they stand on a ladder where 0 represents the worst possible life and 10 the best possible life.'
			}
		]
	},
	{
		name: 'Quality Education',
		slug: 'Quality Education',
		imageSlug: 'quality-education',
		description: '',
		order: 4,
		subgoals: [
			{
				order: 1,
				name: 'Net primary enrollment rate(%)',
				slug: 'Net primary enrollment rate(%)',
				description:
					'The percentage of children of the official school age population who are enrolled in primary education.'
			},
			{
				order: 2,
				name: 'Mean years of schooling(years)',
				slug: 'Mean years of schooling(years)',
				description:
					'The average number of completed years of education of a country‚Äôs population, excluding years spent repeating individual grades.'
			},
			{
				order: 3,
				name: 'Literacy rate(% of population aged 15 to 24)',
				slug: 'Literacy rate(% of population aged 15 to 24)',
				description:
					'The percentage of youth, aged 15 to 24, who can both read and write a short simple statement on everyday life with understanding.'
			},
			{
				order: 4,
				name:
					'Gross intake ratio to last grade of lower secondary education(%)',
				slug:
					'Gross intake ratio to last grade of lower secondary education(%)',
				description:
					'Total number of new entrants in the last grade of lower secondary education, regardless of age, expressed as a percentage of the population at the theoretical entrance age to the last grade of secondary education.This is a proxy measure of secondary completion.'
			}
		]
	},
	{
		name: 'Gender Equality',
		slug: 'Gender Equality',
		imageSlug: 'gender-equality',
		description: '',
		order: 5,
		subgoals: [
			{
				order: 1,
				name:
					'Proportion of women aged 20-24 years who were married or in a union before age 18',
				slug:
					'Proportion of women aged 20-24 years who were married or in a union before age 18',
				description:
					'Number of women aged 20-24 who were first married or in union before age 15 (or before age 18) divided by the total number of women aged 20-24 in the population multiplied by 100'
			},
			{
				order: 2,
				name:
					'Proportion of girls and women aged 15-49 years who have undergone female genital mutilation/cutting, by age',
				slug:
					'Proportion of girls and women aged 15-49 years who have undergone female genital mutilation/cutting, by age',
				description:
					'Number of girls and women aged 15-49 who have undergone FGM/C divided by the total number of girls and women aged 15-49 in the population multiplied by 100'
			},
			{
				order: 3,
				name: 'Seats held by women in national parliaments(%)',
				slug: 'Seats held by women in national parliaments(%)',
				description:
					'The number of seats held by women in single or lower chambers of national parliaments, expressed as a percentage of all occupied seats. Seats refer to the number of parliamentary mandates, or the number of members of parliament.'
			},
			{
				order: 4,
				name: 'Women in ministerial positions(%)',
				slug: 'Women in ministerial positions(%)',
				description:
					'The percentage of women in ministerial positions, reflecting appointments up to 1 January 2017.'
			},
			{
				order: 5,
				name:
					'Demand for family planning satisfied by modern methods(% of women aged 15 to 49 who are married or in unions)',
				slug:
					'Demand for family planning satisfied by modern methods(% of women aged 15 to 49 who are married or in unions)',
				description:
					'The percentage of women of reproductive age, either married or in a union, whose demand for family planning has been met using modern methods of contraception.'
			},
			{
				order: 6,
				name: 'Ratio of female to male mean years of education received',
				slug: 'Ratio of female to male mean years of education received',
				description:
					'The mean years of education received by women aged 25 and older divided by the mean years of education received by men aged 25 and older.'
			},
			{
				order: 7,
				name: 'Ratio of female to male labor force participation rate',
				slug: 'Ratio of female to male labor force participation rate',
				description:
					'Modeled estimate of the proportion of the female population aged 15 years and older that is economically active, divided by the same proportion for men.'
			}
		]
	},
	{
		name: 'Clean Water and Sanitation',
		slug: 'Clean Water and Sanitation',
		imageSlug: 'clean-water-and-sanitation',
		description: '',
		order: 6,
		subgoals: [
			{
				order: 1,
				name: 'Population using at least basic drinking water services(%)',
				slug: 'Population using at least basic drinking water services(%)',
				description:
					'The percentage of the population using at least a basic drinking water service, such as drinking water from an improved source, provided that the collection time is not more than 30 minutes for a round trip, including queuing.'
			},
			{
				order: 2,
				name: 'Population using at least basic sanitation services(%)',
				slug: 'Population using at least basic sanitation services(%)',
				description:
					'The percentage of the population using at least a basic sanitation service, such as an improved sanitation facility that is not shared with other households.'
			},
			{
				order: 3,
				name: 'Freshwater withdrawal(% of available freshwater resources)',
				slug: 'Freshwater withdrawal(% of available freshwater resources)',
				description:
					'The level of water stress: freshwater withdrawal as a proportion of available freshwater resources is the ratio between total freshwater withdrawn by all major sectors and total renewable freshwater resources, after taking into account environmental water requirements. Main sectors, as defined by ISIC standards, include agriculture, forestry and fishing, manufacturing, electricity industry, and services. This indicator is also known as water withdrawal intensity.'
			},
			{
				order: 5,
				name: 'Anthropogenic wastewater that receives treatment(%)',
				slug: 'Anthropogenic wastewater that receives treatment(%)',
				description:
					'The percentage of collected, generated, or produced wastewater that is treated, normalized by the population connected to centralized wastewater treatment facilities. Scores were calculated by multiplying the wastewater treatment summary values, based on decadal averages, with the sewerage connection values to arrive at an overall total percentage of wastewater treated.'
			}
		]
	},
	{
		name: 'Affordable and Clean Energy',
		slug: 'Affordable and Clean Energy',
		imageSlug: 'affordable-and-clean-energy',
		description: '',
		order: 7,
		subgoals: [
			{
				order: 1,
				name: 'Population with access to electricity(%)',
				slug: 'Population with access to electricity(%)',
				description:
					'The percentage of the population who has access to electricity.'
			},
			{
				order: 2,
				name:
					'Population with access to clean fuels & technology for cooking(%)',
				slug:
					'Population with access to clean fuels & technology for cooking(%)',
				description:
					'The percentage of the population primarily using clean cooking fuels and technologies for cooking. Under WHO guidelines, kerosene is excluded from clean cooking fuels.'
			},
			{
				order: 3,
				name: 'Renewable energy share in the total final energy consumption',
				slug: 'Renewable energy share in the total final energy consumption',
				description:
					'The renewable energy share in total final consumption is the percentage of final consumption of energy that is derived from renewable resources. It is calculated by dividing consumption of energy from all renewable sources by total final energy consumption. Renewable energy consumption includes consumption of energy derived from: hydro, solid biofuels, wind, solar, liquid biofuels, biogas, geothermal, marine and waste. Total final energy consumption is calculated from national balances and statistics as total final consumption minus non-energy use'
			},
			{
				order: 4,
				name: 'Consumer affordability of electricity',
				slug: 'Consumer affordability of electricity',
				description:
					'In RISE, electricity is considered affordable if annual expenditure on 30 kWh per month is at most 5 percent of GNI per household of the bottom 20 percent of the population.'
			}
		]
	},
	{
		name: 'Decent Work and Economic Growth',
		slug: 'Decent Work and Economic Growth',
		imageSlug: 'decent-work-and-economic-growth',
		description: '',
		order: 8,
		subgoals: [
			{
				order: 1,
				name: '5-year average GDP Growth per capita(%)',
				slug: '5-year average GDP Growth per capita(%)',
				description:
					'5-year average annual percentage growth rate of GDP per capita based on constant local currency. Aggregates are based on constant 2010 U.S. dollars. GDP per capita is gross domestic product divided by midyear population. GDP at purchaser`s prices is the sum of gross value added by all resident producers in the economy plus any product taxes and minus any subsidies not included in the value of the products. It is calculated without making deductions for depreciation of fabricated assets or for depletion and degradation of natural resources.'
			},
			{
				order: 2,
				name: 'Employment-to-population ratio',
				slug: 'Employment-to-population ratio',
				description:
					'The employment-to-population ratio is the proportion of a country‚Äôs working- age population that is employed. Employment comprises all persons of working age who during a specified brief period, such as one week or one day, were in the following categories: a) paid employment (whether at work or with a job but not at work); or b) self-employment (whether at work or with an enterprise but not at work).'
			},
			{
				order: 3,
				name: 'Victims of modern slavery(per 1,000 population)',
				slug: 'Victims of modern slavery(per 1,000 population)',
				description:
					'Estimation of the number of people in modern slavery. Modern slavery is defined as people in forced labor or forced marriage. It is calculated based on standardized surveys and Multiple Systems Estimation (MSE).'
			},
			{
				order: 4,
				name:
					'Adults with an account at a bank or other financial institution or with a mobile-money-service provider(% of population aged 15 or over)',
				slug:
					'Adults with an account at a bank or other financial institution or with a mobile-money-service provider(% of population aged 15 or over)',
				description:
					'The percentage of adults, 15 years and older, who report having an account (by themselves or with someone else) at a bank or another type of financial institution, or who have personally used a mobile money service within the past 12 months.'
			},
			{
				order: 5,
				name: 'Starting Business',
				slug: 'Starting Business',
				description:
					'Procedures to legally start and formally operate a compuny (number), time required to complete each procedure (calendar days), cost required to complete each procedure (% of income per capita), Paid-in minimun capital (% of income per capita)'
			}
		]
	},
	{
		name: 'Industry, Innovation and Infrastructure',
		slug: 'Industry, Innovation and Infrastructure',
		imageSlug: 'industry-innovation-and-infrastructure',
		description: '',
		order: 9,
		subgoals: [
			{
				order: 1,
				name: 'Infrastructure score',
				slug: 'Infrastructure score',
				description:
					'Infrastructure is one of the four sub-categories that are used to calculate the Sustainable Economic Opportunity category score within the IIAG. It consists of five indicators 1) the quality of roads; level of public satisfaction with government maintenance of roads and bridges; adequacy of the rail network for business needs; and quality of air transport and aviation safety; 2) the quality of the electricity supply and level of public satisfaction with the provision of a reliable electricity supply by the government; 3) the extent to which IT infrastructure is adequate for business needs; subscriptions to a mobile telephone service; households with a computer; and internet subscriptions; 4) the percentage of the total population that is served with drinking water from an improved source, provided collection time is not more than 30 minutes for a round trip, including queuing. Improved sources include piped water, boreholes or tubewells, protected dug wells, protected springs, rainwater, and packaged or delivered water; and 5) the extent to which the public are satisfied with how the government is handling providing water and sanitation services.'
			},
			{
				order: 2,
				name:
					'Logistics performance index: Quality of trade and transport-related infrastructure(worst 1-5 best)',
				slug:
					'Logistics performance index: Quality of trade and transport-related infrastructure(worst 1-5 best)',
				description:
					'Survey-based average assessment of the quality of trade and transport related infrastructure, e.g. ports, roads, railroads and information technology, on a scale from 1 (worst) to 5 (best).'
			},
			{
				order: 3,
				name: 'Expenditure on research and development(% of GDP)',
				slug: 'Expenditure on research and development(% of GDP)',
				description:
					'Gross domestic expenditure on scientific research and experimental development (R&D) expressed as a percentage of Gross Domestic Product (GDP). We assumed zero R&D expenditure for low-income countries that do not report any data.'
			},
			{
				order: 4,
				name: 'Scientific and technical journal articles(per 1,000 population)',
				slug: 'Scientific and technical journal articles(per 1,000 population)',
				description:
					'The number of scientific and technical journal articles published, that are covered by the Science Citation Index (SCI) or the Social Sciences Citation Index (SSCI). Articles are counted and assigned to a country based on the institutional address(es) listed in the article.'
			},
			{
				order: 5,
				name: 'Mobile broadband subscriptions(per 100 population)',
				slug: 'Mobile broadband subscriptions(per 100 population)',
				description:
					'The number of mobile broadband subscriptions per 100 population. Mobile broadband subscriptions refer to subscriptions to mobile cellular networks with access to data communications (e.g. the Internet) at broadband speeds, irrespective of the device used to access the internet.'
			},
			{
				order: 60,
				name: 'Population using the Internet(%)',
				slug: 'Population using the Internet(%)',
				description:
					'The percentage of the population who used the Internet from any location in the last three months. Access could be via a fixed or mobile network.'
			}
		]
	},
	{
		name: 'Reduced Inequalities',
		slug: 'Reduced Inequalities',
		imageSlug: 'reduced-inequalities',
		description: '',
		order: 10,
		subgoals: [
			{
				order: 1,
				name: 'Gini coefficient adjusted for top income',
				slug: 'Gini coefficient adjusted for top income',
				description:
					'The Gini coefficient adjusted for top revenues unaccounted for in household surveys. This indicator takes the average of the unadjusted Gini and the adjusted Gini.'
			}
		]
	},
	{
		name: 'Sustainable Cities and Communities',
		slug: 'Sustainable Cities and Communities',
		imageSlug: 'sustainable-cities-and-communities',
		description: '',
		order: 11,
		subgoals: [
			{
				order: 1,
				name: 'Proportion of urban population living in slums',
				slug: 'Proportion of urban population living in slums',
				description:
					'The percentage of the urban population that live in households lacking one or more of the following basic services: improved water, improved sanitation, durable housing, sufficient living area or security of tenure.'
			},
			{
				order: 2,
				name: 'Access to improved water source, piped(% of urban population)',
				slug: 'Access to improved water source, piped(% of urban population)',
				description:
					'The percentage of the urban population with access to improved drinking water piped on premises. An "improved" drinking-water source is one that, by the nature of its construction and when properly used, adequately protects the source from outside contamination, particularly fecal matter.'
			},
			{
				order: 3,
				name: 'Satisfaction with public transport(%)',
				slug: 'Satisfaction with public transport(%)',
				description:
					'The percentage of the surveyed population that responded "satisfied" to the question "In the city or area where you live, are you satisfied or dissatisfied with the public transportation systems?".'
			},
			{
				order: 4,
				name:
					'Annual mean concentration of particulate matter of less than 2.5 microns in diameter(PM2.5)(μg/m³)',
				slug:
					'Annual mean concentration of particulate matter of less than 2.5 microns in diameter(PM2.5)(μg/m³)',
				description:
					'Air pollution measured as the population-weighted mean annual concentration of PM2.5 for the urban population in a country. PM2.5 is suspended particles measuring less than 2.5 microns in aerodynamic diameter, which are capable of penetrating deep into the respiratory tract and can cause severe health damage.'
			}
		]
	},
	{
		name: 'Responsible Consumption and Production',
		slug: 'Responsible Consumption and Production',
		imageSlug: 'responsible-consumption-and-production',
		description: '',
		order: 12,
		subgoals: [
			{
				order: 1,
				name: 'Municipal solid waste(kg/capita/day)',
				slug: 'Municipal solid waste(kg/capita/day)',
				description:
					'The amount of waste collected by or on behalf of municipal authorities and disposed of through the waste management system. Waste from agriculture and from industries are not included.'
			},
			{
				order: 2,
				name: 'Electronic waste(kg/capita)',
				slug: 'Electronic waste(kg/capita)',
				description:
					'Waste from electrical and electronic equipment, estimated based on figures for domestic production, imports and exports of electronic products, as well as product lifespan data.'
			},
			{
				order: 3,
				name: 'Natural Resource Value Realization Score',
				slug: 'Natural Resource Value Realization Score',
				description:
					'This indicator is a subcomponent of the Resource Governance Index, which assesses policies and practices that authorities employ to govern their countries‚Äô oil, gas and mining industries. Value Realization covers the governance of allocating extraction rights, exploration, production, environmental protection, revenue collection and state-owned enterprises.'
			}
		]
	},
	{
		name: 'Climate Action',
		slug: 'Climate Action',
		imageSlug: 'climate-action',
		description: '',
		order: 13,
		subgoals: [
			{
				order: 1,
				name:
					'People affected by climate-related disasters(per 100,000 population)',
				slug:
					'People affected by climate-related disasters(per 100,000 population)',
				description:
					'The yearly average number of people that have died, been left injured, homeless or in need of basic survival needs due to climate related disasters over the last five year period per 100,000 population.'
			},
			{
				order: 2,
				name: 'Energy-related CO₂ emissions(tCO₂/capita)',
				slug: 'Energy-related CO₂ emissions(tCO₂/capita)',
				description:
					'Emissions of CO‚ÇÇ that arise from the consumption of energy. This includes emissions due to the consumption of petroleum, natural gas, coal, and also from natural gas flaring.'
			},
			{
				order: 3,
				name: 'CO₂ emissions embodied in imports(tCO₂/capita)',
				slug: 'CO₂ emissions embodied in imports(tCO₂/capita)',
				description: 'CO‚ÇÇ emissions embodied in imported goods and services.'
			},
			{
				order: 4,
				name: 'CO₂ emissions embodied in fossil fuel exports(kg/capita)',
				slug: 'CO₂ emissions embodied in fossil fuel exports(kg/capita)',
				description:
					'CO‚ÇÇ emissions embodied in the exports of coal, gas, and oil. Calculated using a 5-year average of fossil fuel exports and converting exports into their equivalent CO‚ÇÇ emissions. Exports for each fossil fuel are capped at the country`s level of production.'
			}
		]
	},
	{
		name: 'Life Below Water',
		slug: 'Life Below Water',
		imageSlug: 'life-below-water',
		description: '',
		order: 14,
		subgoals: [
			{
				order: 1,
				name: 'Ocean Health Index: Clean Waters(worst 0-100 best)',
				slug: 'Ocean Health Index: Clean Waters(worst 0-100 best)',
				description:
					'The clean waters subgoal of the Ocean Health Index measures to what degree marine waters under national jurisdictions have been contaminated by chemicals, excessive nutrients (eutrophication), human pathogens, and trash.'
			},
			{
				order: 2,
				name:
					'Mean area that is protected in marine sites important to biodiversity(%)',
				slug:
					'Mean area that is protected in marine sites important to biodiversity(%)',
				description:
					'The mean percentage area of marine Key Biodiversity Areas (sites that are important for the global persistence of marine biodiversity) that are protected.'
			},
			{
				order: 3,
				name:
					'Fish caught from overexploited or collapsed stocks(% of total catch)',
				slug:
					'Fish caught from overexploited or collapsed stocks(% of total catch)',
				description:
					'The percentage of a country total catch, within its exclusive economic zone (EEZ), that is comprised of species that are overexploited or collapsed, weighted by the quality of fish catch data.'
			},
			{
				order: 4,
				name: 'Fish caught by trawling(%)',
				slug: 'Fish caught by trawling(%)',
				description:
					'The percentage of fish caught by trawling, a method of fishing in which industrial fishing vessels drag large nets (trawls) along the seabed.'
			}
		]
	},
	{
		name: 'Life on Land',
		slug: 'Life on Land',
		imageSlug: 'life-on-land',
		description: '',
		order: 15,
		subgoals: [
			{
				order: 1,
				name:
					'Mean area that is protected in terrestrial sites important to biodiversity(%)',
				slug:
					'Mean area that is protected in terrestrial sites important to biodiversity(%)',
				description:
					'The mean percentage area of terrestrial Key Biodiversity Areas (sites that are important for the global persistence of biodiversity) that are protected.'
			},
			{
				order: 2,
				name: 'Permanent deforestation(% of forest area, 5 year average)',
				slug: 'Permanent deforestation(% of forest area, 5 year average)',
				description:
					'The mean annual percentage of permanent deforestation over the period 2014 to 2018. Permanent deforestation refers to tree cover removal for urbanization, commodity production and certain types of small-scale agriculture. It does not include temporary forest loss due to the forestry sector or wildfires.'
			},
			{
				order: 3,
				name: 'Red List Index of species survival(worst 0-1 best)',
				slug: 'Red List Index of species survival(worst 0-1 best)',
				description:
					'The change in aggregate extinction risk across groups of species. The index is based on genuine changes in the number of species in each category of extinction risk on The IUCN Red List of Threatened Species.'
			}
		]
	},
	{
		name: 'Peace, Justice and Strong Institutions',
		slug: 'Peace, Justice and Strong Institutions',
		imageSlug: 'peace-justice-and-strong-institutions',
		description: '',
		order: 16,
		subgoals: [
			{
				order: 1,
				name: 'Homicides(per 100,000 population)',
				slug: 'Homicides(per 100,000 population)',
				description:
					'The number of intentional homicides per 100,000 people. Intentional homicides are estimates of unlawful homicides purposely inflicted as a result of domestic disputes, interpersonal violence, violent conflicts over land resources, intergang violence over turf or control, and predatory violence and killing by armed groups. Intentional homicide does not include all intentional killing, such as killing in armed conflict.'
			},
			{
				order: 2,
				name: 'Conflict-related deaths per 100,000',
				slug: 'Conflict-related deaths per 100,000',
				description:
					'This indicator reports the number of battle related deaths in armed conflict, and is reported per 100,000 inhabitants in the country. It combines both military and civilian battle related deaths from three datasets collected by the UCDP on: intrastate and interstate organized armed conflict, conflicts between non-state groups, and one-sided violence perpetrated by an armed group against un-armed actors. The data sources used by UCDP include publicly available documents from news agencies, academic journals, NGOs, and other organizations, which are used to list and aggregate individual conflict events to the level of each country or actor for each year. Since reported deaths can vary widely between different sources, the indicator uses the estimate from what the UCDP considers the best or most credible sources. For conflicts or actors spanning across several countries, the number of deaths is divided evenly across the countries involved.'
			},
			{
				order: 3,
				name:
					'Percentage of population who feel safe walking alone at night in the city or area where they live(%)',
				slug:
					'Percentage of population who feel safe walking alone at night in the city or area where they live(%)',
				description:
					'The percentage of the surveyed population that responded "Yes" to the question "Do you feel safe walking alone at night in the city or area where you live?"'
			},
			{
				order: 4,
				name: 'Children involved in child labor(% of population aged 5 to 14)',
				slug: 'Children involved in child labor(% of population aged 5 to 14)',
				description:
					'The percentage of children, between the age of 5-14 years old, involved in child labor at the time of the survey. A child is considered to be involved in child labor under the following conditions: (a) children 5-11 years old who, during the reference week, did at least one hour of economic activity or at least 28 hours of household chores, or (b) children 12-14 years old who, during the reference week, did at least 14 hours of economic activity or at least 28 hours of household chores. We assumed 0% child labor for high-income countries for which no data was reported.'
			},
			{
				order: 5,
				name: 'Property Rights',
				slug: 'Property Rights',
				description:
					'This indicator captures the extent to which the government protects and enforces private property and contract rights. It aggregates four aspects: 1) the extent to which government authorities ensure well-defined rights of private property and regulate the acquisition, benefits, use and sale of property, 2) the extent to which a country‚Äôs legal framework allows individuals to freely accumulate private property, secured by clear laws that are enforced effectively by the government. It measures the degree to which a country‚Äôs laws protect private property rights and the extent to which those laws are respected. It also assesses the likelihood that private property will be expropriated by the state. Five different components are taken into account, which are as follows: physical property rights; intellectual property rights; strength of investor protection; risk of expropriation; quality of land administration; 3) the strength of the protection of property rights, including financial assets, ranging from extremely weak to extremely strong; and 4) the extent to which private economic activity is facilitated by an effective legal system and rule-based governance structure in which property and contract rights are reliably respected and enforced. It assesses: (a) legal basis for secure property and contract rights; (b) predictability, transparency and impartiality of laws affecting economic activity, and their application by the judiciary; (c) difficulty in obtaining business licenses; and (d) crime and violence as an impediment to economic activity'
			},
			{
				order: 6,
				name: 'Access to justice',
				slug: 'Access to justice',
				description:
					'This indicator assesses the extent to which citizens enjoy secure and effective access to justice.'
			},
			{
				order: 7,
				name: 'Corruption Perception Index(worst 0-100 best)',
				slug: 'Corruption Perception Index(worst 0-100 best)',
				description:
					'The perceived levels of public sector corruption, on a scale from 0 (highest level of perceived corruption) to 100 (lowest level of perceived corruption). The CPI aggregates data from a number of different sources that provide perceptions of business people and country experts.'
			},
			{
				order: 8,
				name: 'Public Sector Accountability & Transparency',
				slug: 'Public Sector Accountability & Transparency',
				description:
					'This indicator captures the extent of executive corruption and the extent to which the executive and public employees can be held to account by the electorate, legislative and judiciary. It consists of two sub-indicators: the first sub-indicator assesses how routinely members of the executive, or their agents, grant favors in exchange for bribes, kickbacks, or other material inducements, and how often they steal, embezzle, or misappropriate public funds or other state resources for personal or family use. The second sub-indicator captures the extent to which the executive and public employees can be held to account by the electorate, legislative and judiciary.'
			},
			{
				order: 9,
				name:
					'Birth registrations with civil authority(% of children under 5 years old)',
				slug:
					'Birth registrations with civil authority(% of children under 5 years old)',
				description:
					'The percentage of children under the age of five whose births are reported as being registered with the relevant national civil authorities.'
			},
			{
				order: 10,
				name: 'Press Freedom Index(best 0-100 worst)',
				slug: 'Press Freedom Index(best 0-100 worst)',
				description:
					'Degree of freedom available to journalists in 180 countries and regions, determined by pooling the responses of experts to a questionnaire devised by RSF.'
			}
		]
	},
	{
		name: 'Partnerships for the Goals',
		slug: 'Partnerships for the Goals',
		imageSlug: 'partnerships-for-the-goals',
		description: '',
		order: 17,
		subgoals: [
			{
				order: 1,
				name: 'Tax revenue(% GDP)',
				slug: 'Tax revenue(% GDP)',
				description:
					'Tax revenues are compulsory transfers to the central government for public purposes. Certain compulsory transfers, such as fines, penalties, and most social security contributions, are excluded. Refunds and corrections of erroneously collected tax revenues are treated as negative revenue. It is reported as a percentage of GDP.'
			},
			{
				order: 2,
				name: 'Government spending on health and education(% of GDP)',
				slug: 'Government spending on health and education(% of GDP)',
				description:
					'The sum of public expenditure on health from domestic sources and general government expenditure on education (current, capital, and transfers) expressed as a percentage of GDP.'
			},
			{
				order: 3,
				name: 'Level of customs duties on imports',
				slug: 'Level of customs duties on imports',
				description:
					'This indicator is the average level of tariffs applied to imports from intra-regional trade. This average is obtained across all the regional economic communities (RECs) with which a given a country trades. The African Union objective is for a free trade zone without customs and duties to boost the level of intra-African trade. This indicator is one of the metrics under the "Trade Integration" component of the African Integration Index.'
			},
			{
				order: 4,
				name: 'Visa Requirement',
				slug: 'Visa Requirement',
				description:
					'The visa score for country x is calculated by adding the value of 0 times the number of countries that do not need a visa to enter country x, plus the value of 1 times the number countries that need visa on arrival in country x, plus the value of 3 times the number of countries that need a visa to enter country x.'
			},
			{
				order: 5,
				name: 'Governmental Statistical Capacity',
				slug: 'Governmental Statistical Capacity',
				description:
					'The Statistical Capacity Indicator is a composite score assessing the capacity of a country‚Äôs statistical system. It is based on a diagnostic framework assessing the following areas: methodology; data sources; and periodicity and timeliness. Countries are scored against 25 criteria in these areas, using publicly available information and/or country input. The overall Statistical Capacity score is then calculated as a simple average of all three area scores on a scale of 0-100.'
			}
		]
	}
];
