import ApiService from '@/common/api.service';

export const SubprocessService = {
    query(params) {
        return ApiService.query('/ewg/subprocess/', params);
    },
    get(slug) {
        return ApiService.get(`/ewg/subprocess`, slug);
    }
};
