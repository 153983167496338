<template>
	<Modal
		classes="modal--saved-content"
		class="modal--saved"
		:class="{ 'modal--visual': alert.type !== 'text' }"
		@close="handleAlertClose"
		v-if="alert"
	>
		<template #header>
			<VHeading level="2">{{ alert.title }}</VHeading>
		</template>
		<template #content>
			<img
				:src="require(`@/assets/imgs/icons/${icon}.svg`)"
				alt="icon"
				class="checkmark"
				v-if="icon"
			/>
			<VText 
                size="small" 
                weight="semibold" 
                v-html="alert.content"
            ></VText>
		</template>
	</Modal>
</template>

<script>
import { mapState } from 'vuex';
import { CLOSE_ALERT } from '@/store/mutations.type';

export default {
	name: 'Alert',
	computed: {
		...mapState({
			alert: state => state.alert.options
		}),
		icon() {
			switch (this.alert.type) {
				case 'info':
					return 'icon-info-blue-big';
				case 'text':
					return null;
				default:
					return 'icon-check-big';
			}
		}
	},
	methods: {
		handleAlertClose(event) {
			event.preventDefault();
			event.stopPropagation();
			this.$store.commit(CLOSE_ALERT);
		}
	}
};
</script>
