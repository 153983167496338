import Vue from 'vue';

let components = {
    common: require.context('@/components/common', true, /\.(js|vue)$/i),
    ui: require.context('@/components/ui', true, /\.(js|vue)$/i)
};

Object.keys(components).forEach(item => {
    components[item].keys().map(key => {
        const name = key.match(/\w+/)[0];
        return Vue.component(name, components[item](key).default);
    });
});
