import { FETCH_CONTENT } from '@/store/actions.type';

export default {
	data() {
		return {
			content: null
		};
	},
	methods: {
		async fetchContent(slug) {
			const { data: { data: { content } } } = await this.$store.dispatch(FETCH_CONTENT, slug);
			this.content = content;
		}
	}
};
