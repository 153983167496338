import { SEND_MESSAGE } from '@/store/actions.type';

import { ContactUsService } from '@/common/contact-us.service';

const actions = {
	async [SEND_MESSAGE](context, form) {
		return ContactUsService.post(form);
	}
};
export default {
	actions
};
