import {
    FETCH_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PASSWORD,
    FETCH_PREFERENCES,
    UPDATE_PREFERENCES
} from '../../actions.type';

import {SET_PROFILE, SET_PREFERENCES} from '../../mutations.type';

import profile from '@/data/profile/profile';
import preferences from '@/data/profile/preferences';

import Errors from '@/helpers/Errors';

import {ProfileService} from '@/common/profile/profile.service';
import {PasswordService} from '@/common/profile/password.service';
import {PreferenceService} from '@/common/profile/preference.service';

const state = {
    profile: {
        firstName: '',
        lastName: '',
        email: '',
        profilePhoto: {},
        initialPhoto: null
    },
    preferences: []
};

const actions = {
    async [FETCH_PROFILE](context) {
        const {data} = await ProfileService.get();
        context.commit(SET_PROFILE, data.data);
    },
    async [UPDATE_PROFILE](context, data) {
        return ProfileService.post(data);
    },
    async [UPDATE_PASSWORD](context, data) {
        return PasswordService.put(data);
    },
    async [FETCH_PREFERENCES](context) {
        const {data} = await PreferenceService.get();
        context.commit(SET_PREFERENCES, data.data);
    },
    async [UPDATE_PREFERENCES](context, form) {
        const {data} = await PreferenceService.put(form);
        context.commit(SET_PREFERENCES, data.data);
    }
};

const mutations = {
    [SET_PROFILE](state, data) {
        state.profile.firstName = data.firstName;
        state.profile.lastName = data.lastName;
        state.profile.email = data.email;
        state.profile.initialPhoto = data.profilePhoto
            ? data.profilePhoto
            : require('@/assets/imgs/profile/default_user.jpg');
    },
    [SET_PREFERENCES](state, data) {
        state.preferences = data;
    }
};

export default {
    state,
    actions,
    mutations
};
