import { FETCH_EWG_COUNTRIES } from "@/store/actions.type"
import { SET_EWG_COUNTRIES } from "@/store/mutations.type";

import { CountryService } from '@/common/ewg/country.service';

import countries from "@/data/ewg/countries";

const state = {
    countries: [],
    ewgFilters: {
        country: null
    }
}

const actions = {
    async [FETCH_EWG_COUNTRIES]({ commit }) {
        const { data } = await CountryService.query();
        commit(SET_EWG_COUNTRIES, data.data);
    }
}

const mutations = {
	[SET_EWG_COUNTRIES](state, data) {
        state.countries = data;
	}
}

export default {
    state,
    actions,
    mutations
}