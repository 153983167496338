<template>
	<transition name="fade">
		<div class="modal-section modal-section--mobile">
			<div class="gr gr--wrap modal-section__inner">
				<div class="col col--1@tablet hide show@tablet"></div>

				<div class="col col--12 col--10@tablet">
					<div class="modal-section__card" v-click-outside="closeModal">
						<div
							class="modal-section__card__close cursor-pointer"
							:class="{ 'modal-section__card__close--black': authModal }"
							@click="closeModal"
						>
							<img src="@/assets/imgs/icons/icon-close.svg" alt="" />
						</div>

						<div class="modal-section__card__header" @click.stop>
							<slot name="header"></slot>
						</div>
						<div
							class="modal-section__card__content"
							:class="classes"
							@click.stop
						>
							<slot name="content"></slot>
						</div>

						<div class="modal-section__card__footer" v-if="$slots.footer">
							<slot name="footer"></slot>
						</div>
					</div>
				</div>

				<div class="col col--1@tablet hide show@tablet"></div>
			</div>
		</div>
	</transition>
</template>

<script>
import { SET_ACTIVE_MODAL } from '@/store/mutations.type';

export default {
	name: 'Modal',
	props: {
		classes: {
			type: String,
			default: ''
		},
		authModal: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		closeModal(event) {
			this.$store.commit(SET_ACTIVE_MODAL, null);
			this.$emit('close', event);
		}
	}
};
</script>
