<template>
	<input
		class="form__input"
		:autocomplete="autocomplete"
		:class="{ 'form__input--invalid': error }"
		:value="value"
		@input="$emit('input', $event.target.value)"
	/>
</template>

<script>
export default {
	props: {
		value: {},
		error: { type: String },
		autocomplete: { type: String, default: 'off' }
	}
};
</script>
