import ApiService from '@/common/api.service';

export const VisualService = {
    get() {
        return ApiService.get('/visual')
    },
    post(form) {
        return ApiService.post('/visual', form)
    },
    delete(id) {
        return ApiService.delete(`/visual/${id}`)
    }
};
