<template>
    <button
        type="button"
        :class="mergedClasses"
        :disabled=disabled
        @click="handleClick"
     >
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            validator: val => ['black', 'outline-black', 'primary', 'primary-dark'].includes(val)
        },
        size: {
            type: String,
            validator: val => ['big', 'small'].includes(val)
        },
        social: {
            type: String,
            validator: val => ['google', 'linkedin', 'twitter', 'facebook'].includes(val)
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        classes: {
            type: String,
            default: ''
        },
        route: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        mergedClasses() {
            let classes = 'button';

            if (this.size) classes = classes.concat(` button--${this.size}`);
            if (this.color) classes = classes.concat(` button__${this.color}`);
            if (this.uppercase) classes = classes.concat(' button--uppercase');
            if (this.social) classes = classes.concat(` button--social button--social--${this.social}`);
            if (this.social) classes = classes.concat(` button--social button--social--${this.social}`);
            if (this.classes) classes = classes.concat(` ${this.classes}`);

            return classes;
        }
    },
    methods: {
		handleClick(event) {
            this.$emit('click', event);

            if (!this.route) return;
            event.preventDefault();
			if (this.$route.path !== this.route) {
                this.$router.replace(this.route);
            }
		}
    }
};
</script>
