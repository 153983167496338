<template>
	<component
		:is="`h${level}`"
		:class="mergedClasses"
		@click="$emit('click', $event)"
	>
		<slot></slot>
	</component>
</template>

<script>
export default {
	props: {
		level: {
			type: String,
			required: true,
			validator: val => ['1', '2', '3', '4', '5', '6'].includes(val)
		},
		weight: {
			type: String,
			validator: val => ['light', 'semibold', 'bold'].includes(val)
		},
		size: {
			type: String,
			validator: val => ['big', 'bigger', 'small'].includes(val)
		},
		screen: {
			type: String,
			validator: val => ['mobile'].includes(val)
		},
		classes: String
	},
	computed: {
		mergedClasses() {
			let classes = `h${this.level}`;
			if (this.classes) classes = classes.concat(` ${this.classes}`);
			if (this.screen) classes = classes.concat(` h${this.level}--${this.screen}`);
			if (this.weight) classes = classes.concat(` h${this.level}--${this.weight}`);
			if (this.size) classes = classes.concat(` h${this.level}--${this.size}`);

			return classes;
		}
	}
};
</script>
