<template>
	<a :href="href" :class="mergedClasses" @click="handleClick">
		<slot />
	</a>
</template>

<script>
export default {
	props: {
		size: {
			type: String,
			validator: val => ['big', 'regular', 'small'].includes(val)
		},
		align: {
			type: String,
			validator: val => ['right', 'center'].includes(val)
		},
		href: {
			type: String,
			default: '#'
		},
		route: {
			type: String
		},
		classes: String
	},
	computed: {
		mergedClasses() {
			let classes = 'link';
			if (this.size) classes = classes.concat(` link--${this.size}`);
			if (this.align) classes = classes.concat(` ${this.align}`);
			if (this.classes) classes = classes.concat(` ${this.classes}`);

			return classes;
		}
	},
	methods: {
		handleClick(event) {
			this.$emit('click', event);

			if (!this.route) return;
			event.preventDefault();
			if (this.$route.path !== this.route) {
				this.$router.replace(this.route);
			}
		}
	}
};
</script>
